.container {
  width: 100%;
  max-width: 711px;
  height: 248px;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 20px 40px 20px 20px;

  .left {
    display: flex;
    flex-direction: column;
    gap: 10px;
    transform: translateY(-12px);

    h4 {
      font-size: 24px;
      font-weight: 700;
      line-height: 31.39px;
      letter-spacing: 0em;
      color: #0a0a0a;
    }

    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      color: #0a0a0a;
      width: 100%;
      white-space: nowrap;
    }

    button {
      width: 172px;
      height: 44px;
      border-radius: 22px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      color: #ffffff;
      background-color: #270c66;
    }
  }
}

@media screen and (max-width: 1600px) {
  .container {
    padding: 20px 30px 20px 20px;
    gap: 25px;

    .right {
      img {
        width: 141px;
        height: 141px;
      }
    }
    .left {
      gap: 7px;
      h4 {
        font-size: 22px;
        line-height: 28px;
      }
      p {
        font-size: 13px;
        line-height: 18px;
        white-space: nowrap;
        margin-bottom: 5px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 100%;

    .right {
      img {
        width: 125px;
        height: 125px;
      }
    }
    .left {
      transform: none;
      gap: 13px;
      h4 {
        font-size: 18px;
        line-height: 22px;
        width: 100%;
        max-width: 300px;
      }
      p {
        font-size: 14px;
        white-space: normal;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    flex-direction: column;
    align-items: center;
    height: auto;
    gap: 15px;

    .right {
      img {
        width: 125px;
        height: 125px;
      }
    }
    .left {
      align-items: center;
      gap: 10px;
      h4 {
        text-align: center;
      }
      p {
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;

        br {
          display: none;
        }
      }
    }
  }
}
