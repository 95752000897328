.container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;

  border-radius: 22px;
  box-shadow: 0px 4px 34px 0px #0000001a;
  padding: 35px 50px;
  width: 100%;
  max-width: 1195px;
  min-height: 500px;

  .pagination {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    gap: 10px;
    font-size: 22px;

    .btn{
      background: rgb(222, 20, 104);
      font-size: 22px;
      border: none;
      color: white;
      padding: 0 10px;
      cursor: pointer;
    }
  }

  .loadMoreButton {
    background: rgb(222, 20, 104);
    color: white;
    border-radius: 9px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 22px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    padding: 25px 20px;
  }
}

@media screen and (max-width: 1100px) {
  .container {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    width: fit-content;
  }
}

@media screen and (max-width: 992px) {
  .container {
    align-self: center;
  }
}

@media screen and (max-width: 540px) {
  .container {
    width: 100%;
    padding: 0px;
    box-shadow: none;
    border-radius: none;
  }
}

@media screen and (max-width: 480px) {
  .container {
    gap: 20px;
  }
}
