.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;

  .item {
    width: 309px;
    height: 245px;
    border-radius: 22px;
    background: #ffffff;
    box-shadow: 0px 4px 34px 0px #0000001a;
    margin-bottom: 20px;

    .top {
      padding: 15px 15px;

      .title {
        padding-bottom: 10px;
        h3 {
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0em;
          color: #000000;
          text-align: center;

          span {
            color: #de1468;
          }
        }
      }

      hr {
        border: 1px solid #d2d2d2;
      }

      ul {
        display: flex;
        flex-direction: column;

        li {
          list-style: none;
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 12px 10px;
          border-bottom: 1px solid #efefef;
        }
      }
    }

    .bottom {
      height: 48px;
      border-bottom-left-radius: 22px;
      border-bottom-right-radius: 22px;
      background: #e50000;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      svg {
        width: 21px;
        height: 21px;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .container {
    .item {
        width: 100%;

        .top {
            .title {
                h3 {
                    font-size: 16px;
                }
            }
        }
    }
  }
}
