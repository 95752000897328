.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .mainHeading {
    margin-bottom: 25px;
    font-size: 24px;
    text-align: center;
  }

  // CLIENTS AND EMPLOYEES
  .clientsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 22px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      color: #0a0a0a;
      margin-bottom: 0px;
      text-align: center;
    }
    .clients {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      max-width: 560px;
      gap: 18px;
      margin-bottom: 60px;
      margin-top: 30px;

      .card {
        box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
        background: rgba(255, 255, 255, 1);
        width: 126px;
        height: 116px;
        border-radius: 22px;
        border: 1px solid rgba(255, 255, 255, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        cursor: pointer;
        transition: all 300ms ease-in-out;

        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          object-fit: cover;
        }
        p {
          color: rgba(10, 10, 10, 1);
          line-height: 20.93px;
          font-size: 16px;
          transition: all 300ms ease-in-out;
          text-align: center;
        }
      }
    }
  }

  // SERVICES
  .servicesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h2 {
      font-size: 22px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      color: #0a0a0a;
      margin-bottom: 0px;
      text-align: center;
    }
    .services {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 25px;
      width: 100%;
      max-width: 560px;
      margin-top: 30px;
      margin-bottom: 60px;

      .card {
        width: 100%;
        height: auto;
        min-height: 116px;
        border-radius: 22px;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(255, 255, 255, 1);
        cursor: pointer;
        transition: all 300ms ease-in-out;
        justify-content: space-between;
        .img {
          width: 80px;
          height: 80px;
          border-radius: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 15px;

          h3 {
            font-size: 40px;
            font-weight: 600;
            color: rgba(255, 255, 255, 1);
          }
        }

        .content {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          max-width: 60%;
          .name {
            font-weight: 600;
            font-size: 20px;
            line-height: 26.16px;
            color: #0a0a0a;
          }

          .desc {
            font-size: 15px;
            line-height: 19.62px;
            margin-bottom: 8px;
            color: #000000;
          }

          .row {
            display: flex;
            align-items: center;
            gap: 16px;

            .block {
              height: 27px;
              border-radius: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;

              p {
                font-weight: 600;
                font-size: 14px;
              }

              svg {
                width: 14px;
                height: 14px;
              }

              &:nth-child(1) {
                min-width: 103px;
              }

              &:nth-child(2) {
                min-width: 76px;
              }
            }
          }
        }

        button {
          height: 77px;
          width: 71px;
          border-radius: 22px;
          border: none;
          background: rgba(20, 6, 53, 1);
          padding: 23px, 15px, 24px, 15px;
          font-size: 16px;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  // TIME
  .time {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
    margin-bottom: 30px;

    h3 {
      font-size: 22px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      color: #0a0a0a;
      text-align: center;
    }

    // CUSTOM TIME SELECT SECTION
    .customTime {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      background-color: #dfdfdf48;
      border-radius: 5px;
      margin-bottom: 15px;

      h3 {
        margin-bottom: 12px;
      }

      button {
        width: 130px;
        height: 34px;
        border-radius: 22px;
        border: none;
        background: #de1468;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
        color: #ffffff;
        cursor: pointer;
      }
    }

    // SLOTS SECTION
    .slotsSection {
      .timeslotsError {
        color: red;
        font-size: 14px;
        text-align: center;
      }

      .slots {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        animation-name: fade2;
        animation-duration: 800ms;
        animation-iteration-count: 1;
        animation-direction: normal;

        @keyframes fade2 {
          0% {
            opacity: 0;
            transform: translateY(100px);
          }
          100% {
            opacity: 1;
            transform: translateY(0px);
          }
        }

        h4 {
          color: #0a0a0a;
          font-size: 24px;
          font-weight: 600;
          line-height: 31.39px;
          margin-bottom: 12px;
        }

        .items {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 15px;

          .slot {
            width: 100px;
            min-height: 33px;
            height: auto;
            border-radius: 32px;
            padding: 2px 0px;
            border: 1px solid #0a0a0a;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 300ms ease-in-out;

            p {
              font-weight: 600;
              color: #0a0a0a;
              font-size: 20px;
              text-align: center;
              margin-bottom: -1px;
              transition: all 300ms ease-in-out;
            }
          }
        }

        .waitingListSignup {
          p {
            font-size: 18px;
            line-height: 32px;
          }

          button {
            border: none;
            height: 40px;
            width: fit-content;
            padding: 0px 15px;
            border-radius: 10px;
            color: #ffffff;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
    }

    // SLOTS AND DAY
    .timeDisplay {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-bottom: 15px;

      .block {
        display: flex;
        align-items: center;
        gap: 8px;
        p {
          font-size: 24px;
        }
        span {
          font-size: 20px;
        }
        img {
          width: 19px;
          height: 19px;
          margin-left: 2px;
          margin-bottom: -2px;
        }
      }
    }

    .dropdowns {
      width: 100%;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #d3d3d3;
      border-radius: 5px;
      padding: 20px;
      h3 {
        margin-bottom: 5px;
      }
      h4 {
        margin-bottom: 10px;
        font-weight: 300;
        text-align: center;
      }
      .row {
        display: flex;
        align-items: center;
        gap: 15px;
        width: 100%;
        max-width: 500px;
        select {
          width: 100%;
          height: 48px;
          border-radius: 12px;
          background: #f9f9f9;
          border: 1px solid #d3d3d3;
          padding: 0px 15px;
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          letter-spacing: 0em;
          color: #a7a7a7;
          /** for the dropdown indicator */
          appearance: none;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-position: left 0.8rem center;
          background-size: 1em;
        }
      }
      .error {
        color: red;
      }
    }
  }

  .btn {
    width: 206px;
    height: 44px;
    border-radius: 22px;
    border: none;
    background: #de1468;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: #ffffff;
    cursor: pointer;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .time {
      .timeslotsError {
        font-size: 14px;
        width: 100%;
        max-width: 250px;
      }
    }
  }
}
