.container {
  width: 100%;
  height: 71px;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 25px;
  margin-bottom: 40px;

  .right {
    flex-grow: 1;
    h3 {
      font-size: 20px;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0em;
      color: #000000;
      margin-bottom: 5px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 13px;
      letter-spacing: 0em;
      color: #000000;
    }
  }

  .left {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    .input {
      width: 100%;
      max-width: 541px;
      height: 48px;
      border-radius: 22px;
      background: rgba(232, 228, 228, 0.21);
      border: 1px solid #270c6638;
      display: flex;
      align-items: center;
      padding: 0px 20px;
      input {
        height: 100%;
        width: 100%;
        border: none;

        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        color: #000000;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    height: auto;
    max-width: 500px;
    align-self: center;
    .right {
      h3 {
        font-size: 16px;
      }
      p {
        font-size: 14px;
        line-height: 19px;
      }
    }
    .left {
      width: 100%;
      justify-content: flex-start;
      .input {
        width: 100%;
        max-width: 100%;
        padding: 0px 20px 0px 10px;
      }
    }
  }
}
