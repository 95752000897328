.container {
  display: flex;
  align-items: center;
  gap: 25px;
  width: 30%;
  min-width: 300px;

  .text {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color: #4f4f4f;
    white-space: nowrap;
  }

  .slots {
    display: flex;
    align-items: center;
    gap: 8px;

    .slot {
      cursor: pointer;
      width: 67px;
      height: 37px;
      border-radius: 22px;
      background: #f8f8f8;
      border: 1px solid #dcdcdc;
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-align: center;
      font-size: 16px;
      font-weight: 500 !important;
      line-height: 21px;
      letter-spacing: 0em;
      color: #767676;
    }

    .active {
      border: 1px solid #de1468;
      box-shadow: 0px 4px 34px 0px #0000001a;
      color: #de1468;
    }

    span {
      color: #de1468;
    }
  }
}

@media screen and (max-width: 1400px) {
  .container {
    min-width: 230px;
    gap: 10px;
    .text {
      font-size: 14px;
    }
    .slots {
      gap: 5px;

      .slot {
        width: 57px;
        height: 27px;
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .container {
    flex-grow: 1;
    width: 50%;
    margin-bottom: 20px;
  }
}


@media screen and (max-width: 768px) {
  .container {
    .text {
      font-weight: 600;
      font-size: 16px;
    }
  }
}
