.container {
  width: 100%;
  max-width: 436px;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;

  .head {
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    height: 100px;
    background: #d00254;
    display: flex;
    justify-content: center;

    img {
      width: 111px;
      height: 110px;
      border-radius: 88px;
      object-fit: cover;
      transform: translateY(40px);
    }

    .placeholder {
      width: 111px;
      height: 110px;
      background: #de1468;
      border-radius: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY(40px);

      p {
        color: white;
        font-size: 40px;
      }
    }
  }

  .body {
    padding: 70px 20px 30px 20px;

    h4 {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      color: #0a0a0a;
    }

    .line1 {
      border: 1px solid #d2d2d2;
    }

    ul {
      li {
        list-style: none;
        display: flex;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid #f1f1f1;
        padding: 15px 10px;

        &:last-child {
          border: none;
        }

        p {
          font-size: 20px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0em;
          color: #000000;
        }
      }
    }

    .status {
      background: #f5f5f5;
      padding: 15px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        width: 90px;
        height: 27px;
        border-radius: 32px;

        p {
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          letter-spacing: 0em;
        }

        svg {
          width: 15px;
          height: 15px;
        }
      }

      .completed {
        background: rgba(182, 255, 189, 0.36);
        border: 1px solid #06a816;
        p {
          color: #06a816;
        }
      }
      .notCompleted {
        background-color: #e50000;
        p {
          color: #ffffff;
        }
      }
    }

    .assign {
      margin-bottom: 25px;
      h4 {
        margin-bottom: 10px;
      }

      .field {
        display: flex;
        align-items: center;
        gap: 10px;
        select {
          flex-grow: 1;
          width: auto;
          height: 48px;
          border-radius: 12px;
          border: 1px solid #de1468;
          background: #f9f9f9;
          font-size: 16px;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: 0em;
          color: #de1468;
          padding: 0px 20px;
          /** for the dropdown indicator */
          appearance: none;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-position: left 0.8rem center;
          background-size: 1em;
        }
        button {
          width: 79px;
          height: 44px;
          border-radius: 22px;
          border: none;
          background: #270c66;
          font-size: 16px;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }

    .selectedPlans {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .selectedPlan {
        background: #f9f9f9;
        width: fit-content;
        padding: 10px 12px;
        border-radius: 12px;
      }
    }

    .line2 {
      border: 1px solid #f1f1f1;
      margin-bottom: 25px;
    }

    .options {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      margin-top: 20px;

      button {
        width: 100%;
        max-width: 117.60598754882812px;
        height: 44px;
        border-radius: 22px;
        border: none;
        background: #de1468;
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: #ffffff;
        cursor: pointer;

        &:nth-child(3) {
          max-width: 145px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    max-width: 100%;

    .body {
      h4 {
        font-size: 18px;
      }
      ul {
        li {
          p {
            font-size: 16px;
          }
          img {
            width: 18px;
            height: 18px;
          }
        }
      }

      .assign {
        .field {
          select {
            flex-grow: 0;
            width: 75%;
          }
          button {
            min-width: 70px;
            width: 25%;
          }
        }
      }

      .options {
        gap: 5px;
        button {
          height: 40px;
          width: 30%;
          max-width: auto;
          padding: 0px 3px;
          font-size: 14px;

          &:nth-child(3) {
            width: 40%;
          }
        }
      }
    }
  }
}
