.container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;

  .item {
    position: relative;
    padding: 20px 20px 20px 30px;
    width: 100%;
    max-width: 1050px;
    height: auto;
    min-height: 96px;
    border-radius: 22px;
    background: #ffffff;
    box-shadow: 0px 4px 34px 0px #0000001a;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;

      .name {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        margin-right: 10px;

        h4 {
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0em;
          color: #0a0a0a;
        }
        p {
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: #000000;
        }
      }

      .services {
        display: flex;
        align-items: center;
        gap: 10px;
        .block {
          width: auto;
          height: 27px;
          padding: 0px 10px;
          border-radius: 32px;
          background: rgba(222, 20, 104, 0.15);
          border: 1px solid #de1468;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 3px;

          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0em;
            color: #d00254;
            white-space: nowrap;
          }

          img {
            width: 15px;
            height: 15px;
            margin-left: -2px;
          }
          svg {
            width: 13px;
            height: 13px;
          }
        }
      }
    }

    .left {
      .actions {
        display: flex;
        align-items: center;
        gap: 17px;
        .block {
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;

          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            color: #4f4f4f;
          }
        }
      }
    }
    .tag {
      transform: rotate(-3deg);
      margin-top: -15px;
      margin-left: -15px;
      position: absolute;
      left: 0;
      top: 0;
      width: 130.00000361136816px;
      height: 36.00000100007119px;
      border-radius: 22px;
      border: 1px solid #ffffff;
      box-shadow: 0px 4px 9px 0px #3f3f3f36;
      background: linear-gradient(0deg, #66c00c, #66c00c),
        linear-gradient(0deg, #ffffff, #ffffff);

      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .container {
    order: 2;
  }
}

@media screen and (max-width: 992px) {
  .container {
    align-items: center;
    margin-bottom: 20px;
    .item {
      max-width: 657px;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    gap: 20px;
    .item {
      padding: 15px 15px;
      .right {
        gap: 10px;
        .name {
          margin-left: 10px;
          margin-right: 0px;

          h4 {
            font-size: 14px;
            line-height: 20px;
          }
          p {
            font-size: 12px;
          }
        }

        .services {
          gap: 5px;
          flex-wrap: wrap;
          .block {
            p {
              font-size: 12px;
              line-height: 16px;
            }

            img {
              width: 13px;
              height: 13px;
            }
            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      .left {
        .actions {
          gap: 10px;
          .block {
            gap: 4px;

            p {
              font-size: 12px;
              line-height: 16px;
            }
            img {
              width: 15px;
              height: 15px;
            }
          }
        }
      }

      .tag {
        width: 90px;
        height: 28px;

        p {
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .item {
      .right {
        .services {
          gap: 5px;
          .block {
            height: auto;
            padding: 2px 5px;
            p {
              font-size: 12px;
              line-height: 16px;
            }

            img {
              width: 13px;
              height: 13px;
            }
            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
      .left {
        .actions {
          gap: 7px;
          .block {
            p {
              display: none;
            }
          }
        }
      }
    }
  }
}
