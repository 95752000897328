.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 0px 0px 30px 0px;

  .wrapper {
    width: 100%;
    max-width: 1640px;
    display: flex;
    z-index: 99;

    .main {
      display: flex;
      align-items: flex-start;
      width: 100%;
      padding-left: 20px;
      gap: 40px;

      .line {
        height: 644px;
        border: 1.5px solid #c1c1c1;
      }
      .userPlans {
        display: flex;
        flex-direction: column;
        width: 100%;
        .heading {
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0em;
          color: #0a0a0a;
          margin-bottom: 20px;
        }

        .items {
          display: flex;
          align-items: baseline;
          flex-wrap: wrap;
          gap: 40px;
          width: 100%;
        }
        .noItems {
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .container {
    .wrapper {
      .main {
        flex-direction: column;
        .line {
          width: 100%;
          height: 1px;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    margin-top: 30px;
    .wrapper {
      flex-direction: column;
      align-items: flex-start;

      .main {
        padding: 0px 20px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .wrapper {
      .main {
        gap: 60px;
        align-items: center;
        .line {
          display: none;
        }
        .userPlans {
          align-items: center;
          .heading {
            margin-bottom: 30px;
          }

          .items {
            justify-content: center;
          }
        }
      }
    }
  }
}
