.container {
  width: 116px;
  height: 116px;
  border: 1px solid #de1468;
  border-radius: 12px;
  box-shadow: 0px 4px 34px 0px #0000001a;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  .add {
    position: absolute;
    bottom: -16px;
    left: 0;
    right: 0;
    margin: auto;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 0.65px solid #de1468;
    border-radius: 50%;
    box-shadow: 0px 2.5964913368225098px 22.07017707824707px 0px #0000001a;
    p {
      color: #050505;
      font-size: 26px;
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 90px;
    height: 90px;

    img {
      width: 60px;
      height: 60px;
    }

    .add {
      width: 24px;
      height: 24px;

      p {
        font-size: 20px;
      }
    }
  }
}
