// @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap");
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Assistant', sans-serif !important;
}

html,
body {
  font-family: 'Assistant', sans-serif !important;
  direction: rtl;
}

a {
  text-decoration: none;
  color: unset;
}

option,
textarea,
input {
  font-family: 'Assistant', sans-serif !important;
}

:root {
  --brand-color: #f6f1f1;
  --brand-text-color: #292929;

  --primary-text-color: #111827;
  --secondary-text-color: #888888;
}

input,
select,
button,
textarea,
select {
  outline: none;
}

button {
  transition: all 300ms ease-in-out;
  &:hover {
    opacity: 0.9;
  }
}

// remove arrows from number input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

select,
input {
  &:enabled {
    background-color: unset;
  }
}
