.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h2 {
    margin-bottom: 15px;
    font-size: 24px;
    text-align: center;
  }

  .customer {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
      width: 16px;
      height: 16px;
      margin-bottom: -2px;
    }
    p {
      font-size: 18px;
    }
  }

  .date {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
      width: 16px;
      height: 16px;
    }
    p {
      font-size: 18px;
    }
  }

  .hours {
    display: flex;
    align-items: center;
    gap: 10px;
    direction: ltr;
    margin-bottom: 25px;
    svg {
      width: 15px;
      height: 15px;
    }
    p {
      font-size: 18px;
    }
  }

  .body {
    width: 100%;

    ul {
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 14px 0px;
        border-bottom: 1px solid #f1f1f1;
        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }

        img {
          width: 15px;
          height: 15px;
        }
        p {
        }
      }
    }
  }
}
