.container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 0px;
  width: 100%;
  max-width: 560px;
  margin-bottom: 70px;

  .step {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    position: relative;
    .circle {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background: #efefef;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: #b9b9b9;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0em;
        text-align: center;
      }
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0em;
      text-align: center;
      color: #0a0a0a;
      position: absolute;
      bottom: -32px;
      white-space: nowrap;
    }
  }

  .active {
    .circle {
      background: #f6d8e4;
      border: 1px solid #de1468;
      span {
        color: #de1468;
      }
    }
  }

  .checked {
    .circle {
      background: #DE1468;
      border: 1px solid #de1468;
      span {
        color: #ffffff;
      }
    }
  }

  hr {
    background-color: #ffffff;
    border-top: 1px solid #000000;
    height: 1px;
    width: 195px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    margin-bottom: 50px;
    max-width: 450px;

    .step {
      width: 50px;
      gap: 3px;
      .circle {
        width: 34px;
        height: 34px;

        span {
          font-size: 16px;
        }
      }
      p {
        font-size: 11px;
        line-height: 12px;
        bottom: -29px;
      }
    }

    hr {
      width: 80px;
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    margin-bottom: 30px;
  }
}
