.container {
  width: 100%;
  max-width: 554px;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  padding: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    color: #0a0a0a;
    text-align: center;
  }
  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    color: #0a0a0a;
    text-align: center;
    width: 100%;
    max-width: 326px;
    margin-bottom: 40px;
  }

  .form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    margin-bottom: 30px;

    .field {
      width: 47%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      label {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
        color: #0a0a0a;

        span {
          color: #de1468;
        }
      }

      input,
      select {
        width: 100%;
        height: 48px;
        border-radius: 12px;
        background: #f9f9f9;
        border: 1px solid #d3d3d3;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0em;
        color: #a7a7a7;
        padding: 0px 20px;
      }
      select {
        /** for the dropdown indicator */
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: left 0.8rem center;
        background-size: 1em;
      }

      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
          width: 74px;
        }
        select {
          width: 141px;
        }
      }

      &:nth-child(5),
      &:nth-child(6) {
        width: 100%;
      }
    }
  }

  .card {
    width: 100%;
    height: auto;
    border-radius: 22px;
    border: 1px solid #270c66;
    background: #fbfbfb;
    box-shadow: 0px 4px 34px 0px #0000001a;
    padding: 20px 25px 35px 25px;
    margin-bottom: 30px;

    h4 {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      color: #060a35;
      margin-bottom: 20px;

      span {
        color: #de1468;
      }
    }

    .field {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;

      label {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
        color: #0a0a0a;
      }

      select,
      input {
        width: 100%;
        max-width: 381px;
        height: 48px;
        border-radius: 12px;
        background: #f9f9f9;
        border: 1px solid #d3d3d3;
        color: #a7a7a7;
        padding: 0px 20px;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0em;
      }
      select {
        /** for the dropdown indicator */
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: left 0.8rem center;
        background-size: 1em;
      }
    }

    .periods {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 30px 0px 30px 0px;

      &:last-child {
        margin-bottom: 0px;
      }
      .period {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .check {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          background: #f9f9f9;
          border: 1px solid #d3d3d3;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        p {
          color: #0a0a0a;
          padding: 0px 20px;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0em;
        }
      }
    }
  }

  button {
    width: 100%;
    height: 44px;
    border-radius: 22px;
    border: none;
    background: #de1468;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: #ffffff;
  }
}

@media screen and (max-width: 768px) {
  .container {
    align-self: center;
  }
}
