.container {
  display: flex;
  align-items: baseline;
  gap: 25px;
  width: 55%;
  min-width: 300px;
  // max-width: 480px;
  padding-left: 20px;

  .text {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color: #4f4f4f;
    white-space: nowrap;
  }

  .services {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .service {
      width: fit-content;
      padding: 0px 12px;
      height: 37px;
      border-radius: 22px;
      background-color: transparent;
      border: 1px solid #dadada;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      cursor: pointer;

      p {
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0em;
        color: #c3c3c3;
      }
    }
    .active {
      border: 1px solid #de1468;
      box-shadow: 0px 4px 34px 0px #0000001a;
      background: rgba(255, 172, 205, 0.21);

      p {
        color: #de1468;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .container {
    min-width: 270px;
    gap: 15px;
    .text {
      font-size: 14px;
    }
    .services {
      gap: 8px;
      .service {
        padding: 0px 8px;
        height: 30px;
        gap: 4px;
        p {
          font-size: 14px;
          line-height: 0px;
        }
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .container {
    max-width: 300px;
  }
}

@media screen and (max-width: 1080px) {
  .container {
    width: 90%;
    max-width: 100%;
    flex-grow: 1;
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
    .text {
      font-weight: 600;
      font-size: 16px;
    }
  }
}
