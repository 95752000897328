.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .tableContainer {
    width: 100%;
    overflow-x: auto;
    background-color: #ffffff;
    border-radius: 22px;
    box-shadow: 0px 4px 34px 0px #0000001a;
    padding: 20px;
    
    .customTable {
      width: 100%;
      min-width: 800px;
      border-collapse: collapse;
      table-layout: fixed;
    }

    .customTable th {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: right;
      color: #000000;
      border-bottom: 1px solid #000000;
    }

    .customTable th,
    .customTable td {
      padding: 8px;
    }

    .customTable th {
      &:first-child {
        width: 5%;
      }
    }

    .customTable td {
      &:first-child {
        padding-right: 15px;
        input {
          width: 25px;
          height: 25px;
        }
      }

      &:last-child {
        text-align: end;
        padding-left: 20px;
      }
    }

    .customTable td {
      padding: 15px 8px;
    }

    .accordionHeader {
      border-bottom: 1px solid #000000;
      cursor: pointer;

      td {
        .customer {
          display: flex;
          align-items: center;
          gap: 15px;
          .text {
            display: flex;
            flex-direction: column;
            h5 {
              font-size: 20px;
              font-weight: 700;
              line-height: 26px;
              letter-spacing: 0em;
              color: #0a0a0a;
            }

            p {
              font-size: 15px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0em;
              color: #000000;
            }
          }
        }

        .actions {
          display: flex;
          align-items: center;
          gap: 12px;
          svg {
            width: 34px;
            height: 34px;
          }
        }

        .status {
          width: 100px;
          height: 23px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          p {
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: center;
          }
        }
        .pending {
          background-color: rgba(255, 218, 121, 0.36);
          border: 1px solid #fab400;
          p {
            color: #e2a710;
          }
        }
        .success {
          background-color: rgba(182, 255, 189, 0.36);
          border: 1px solid #06a816;
          p {
            color: #06a816;
          }
        }

        .error {
          background-color: rgba(222, 20, 104, 0.15);
          border: 1px solid #FF0000;
          p {
            color: #FF0000;
          }
        }

        .health {
          width: 90px;
          height: 23px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          border-radius: 20px;
          svg {
            width: 15px;
            height: 15px;
          }
          p {
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: center;
          }
        }
      }
    }

    .accordionHeader.active {
      border-bottom: 1px solid #d2d2d2;
      background-color: #de146805;
    }

    .accordionContent {
      background-color: #de146805;
      td {
        padding: 0px;
        ul {
          display: flex;
          flex-direction: column;

          li {
            display: flex;
            align-items: center;
            gap: 15px;
            border-bottom: 1px solid #f1f1f1;
            padding: 14px 58px 14px 0px;
            cursor: pointer;

            &:last-child {
              border: none;
            }

            p {
              font-size: 20px;
              font-weight: 400;
              line-height: 26px;
              letter-spacing: 0em;
              color: #000000;
            }
          }
        }
      }
    }
  }
}
