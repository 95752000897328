.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 0px 0px 30px 0px;
  position: relative;

  .wrapper {
    width: 100%;
    max-width: 1600px;
    // display: flex;

    .sections {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      gap: 30px;
      padding-left: 20px;
    }
  }

  .saveBtn {
    position: fixed;
    bottom: 20px;
    width: 206px;
    height: 44px;
    border-radius: 22px;
    border: none;
    background: #de1468;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: #ffffff;
    cursor: pointer;
  }
}

@media screen and (max-width: 992px) {
  .container {
    margin-top: 30px;
    .wrapper {
      flex-direction: column;
      align-items: flex-start;

      .sections {
        padding: 0px 20px;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0px 0px 80px 0px;
  }
}
