.container {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;

    .text {
      h1 {
        color: rgb(17, 17, 17/1);
        font-size: 26px;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        color: rgb(136, 136, 136/1);
      }
    }

    button {
      background-color: rgb(17, 24, 39/1);
      border: none;
      border-radius: 8px;
      width: 100px;
      height: 40px;
      color: rgb(255, 255, 255/1);
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    .header {
      margin-bottom: 25px;
      .text {
        h1 {
          font-size: 22px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .header {
      .text {
        h1 {
          font-size: 20px;
        }
      }
      button {
        border-radius: 5px;
        width: 80px;
        height: 35px;
        font-size: 14px;
      }
    }
  }
}
