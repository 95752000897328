.container {
  border-radius: 22px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  padding: 30px;

  .text {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color: #4f4f4f;
    margin-bottom: 40px;
  }

  .days {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    padding: 30px 20px;
  }
}
