.container {
  width: 100%;
  max-width: 541px;
  height: 48px;
  border-radius: 22px;
  background: rgba(232, 228, 228, 0.21);
  border: 1px solid #270c6638;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  input {
    height: 100%;
    width: 100%;
    border: none;

    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color: #000000;
  }
}

@media screen and (max-width: 992px) {
  .container {
    .input {
      width: 100%;
      max-width: 100%;
      padding: 0px 20px 0px 10px;
    }
  }
}
