.container {
  border: 1px solid rgb(229, 231, 235/1);
  padding: 20px;
  border-radius: 5px;

  p {
    color: var(--primary-text-color);
    font-weight: 500;
    margin-bottom: 30px;
  }

  .row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
    position: relative;
    // overflow-x: auto;
    .day {
      display: flex;
      align-items: center;
      margin-right: 40px;
      position: absolute;
      gap: 10px;
      .switch {
        display: flex;
        align-items: center;
      }
      label {
        font-size: 14px;
        color: var(--primary-text-color);
        text-transform: capitalize;
      }
    }

    .time {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-right: 15px;
      margin-left: 170px;
      .slot {
        display: flex;
        align-items: center;
        gap: 15px;

        .timeWrapper {
          display: flex;
          gap: 15px;
          .block {
            input,
            select {
              border: 1px solid rgb(202, 203, 207);
              border-radius: 5px;
              width: 70px;
              height: 35px;
              padding: 8px;
              text-align: center;
              -webkit-appearance: none;
              -moz-appearance: none;
              text-indent: 1px;
              text-overflow: "";
            }
          }

          .break {
            font-size: 16px;
          }

          .add {
            font-size: 20px;
            color: var(--secondary-text-color);
            cursor: pointer;
          }
        }

        .services {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;

          .service {
            border: 1px solid rgb(202, 203, 207);
            border-radius: 5px;
            // width: 70px;
            width: fit-content;
            height: 35px;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            cursor: pointer;

            span {
              border: 1px solid #888;
              border-radius: 100%;
              width: 15px;
              height: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 11px;
              }
            }
            p {
              font-size: 12px;
              margin: 0pc;
            }
          }
          .activeService {
            background-color: #f1f1f1;
            span {
              border: 1px solid #555;
            }
          }
        }
      }
    }

    /* width */
    &::-webkit-scrollbar {
      height: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .rowRTL {
    .day {
      margin-left: 40px;
      margin-right: unset;
    }
    .time {
      margin-right: 170px;
      margin-left: 15px;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .row {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .day {
        position: relative;
      }

      .time {
        margin-left: 0px;
      }
    }
    .rowRTL {
      .time {
        margin-right: 0px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    .row {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .day {
        position: relative;
      }

      .time {
        margin-left: 0px;

        .slot {
          flex-direction: column;
          .timeWrapper {
          }
        }
      }
    }
  }
}
