.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h2 {
    margin-bottom: 40px;
    font-size: 20px;
    text-align: center;
  }

  input {
    width: 100%;
    max-width: 337px;
    border: 2px solid #786a5133;
    background: #ffffff;
    border-radius: 22px;
    padding: 12px 16px 12px 16px;
    margin-bottom: 20px;

    color: #958870;
    font-size: 16px;
  }

  button {
    width: 100%;
    max-width: 337px;
    padding: 12px 70px 12px 70px;
    color: #ffffff;
    font-size: 20px;
    border-radius: 22px;
    border: none;
    background: #d00254;
    cursor: pointer;

    &:disabled {
      opacity: 1;
    }
  }
}
