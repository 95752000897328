.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 10px 20px 10px;
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: 0em;
      color: #0a0a0a;
      margin-bottom: 12px;
    }

    .mainText {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
      color: #0a0a0a;
      margin-bottom: 35px;
      width: 100%;
    }

    .secondWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .plans {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 0px;

        .plan {
          margin-bottom: 40px;
          width: 263px;
          height: auto;
          min-height: 480px;
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          position: relative;

          .head {
            height: 90px;
            width: 100%;
            background: #270c66;
            border-radius: 12px 12px 0px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;

            .tag {
              position: absolute;
              top: -12px;
              left: 0;
              right: 0;
              margin: auto;
              width: 90px;
              height: 24px;
              border-radius: 30px;
              background-color: #de1468;
              display: flex;
              align-items: center;
              justify-content: center;
              p {
                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
                letter-spacing: 0em;
                color: #ffffff;
              }
            }

            h3 {
              font-size: 20px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: center;
              color: #ffffff;
            }
            p {
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: 0em;
              text-align: center;
              color: #ffffff;
            }
          }

          .body {
            border-radius: 0px 0px 12px 12px;
            border: 1px solid #270c66;
            padding: 15px 18px;
            height: 100%;
            display: flex;
            flex-direction: column;
            flex-grow: 2;

            ul {
              display: flex;
              flex-direction: column;
              flex-grow: 2;
              gap: 12px;
              margin-bottom: 18px;
              li {
                display: flex;
                align-items: center;
                gap: 5px;
                svg {
                  width: 12px;
                  height: 12px;
                  min-width: 12px;
                  min-height: 12px;
                }
                p {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 13px;
                  letter-spacing: 0em;
                  span {
                    color: #de1468;
                    font-weight: 700;
                  }
                }
              }
            }

            .price {
              display: flex;
              align-items: flex-end;
              justify-content: center;
              gap: 3px;
              margin-bottom: 14px;
              span {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: -0.03em;
                color: #000000;
              }
              h3 {
                font-size: 40px;
                font-weight: 700;
                line-height: 42px;
                letter-spacing: -0.03em;
                color: #000000;
              }
              p {
                font-family: Assistant;
                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
                letter-spacing: 0em;
                color: #140635;
              }
            }

            .text {
              font-size: 13px;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: 0em;
              text-align: center;
              color: #140635;
              width: 100%;
              max-width: 187px;
              align-self: center;
            }
          }

          .eclipse {
            position: absolute;
            bottom: -7px;
            left: 0;
            right: 0;
            margin: auto;
            width: 18px;
            height: 18px;
            border-radius: 50%;
          }
        }

        .active {
          border: 1px solid #de1468;
          position: relative;

          .head {
            background-color: #de1468;

            .tag {
              background-color: #270c66;
            }

            h3 {
              color: #ffffff;
            }
            p {
              color: #ffffff;
            }
          }
          .body {
            border: none;
          }
        }

        .selected {
          background-color: #fed6d6;

          .body {
            border: none;
          }
        }
      }

      .check {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        width: 100%;
        margin-bottom: 30px;
        cursor: pointer;

        .outer {
          width: 12px;
          height: 12px;
          border: 1px solid #de1468;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          .inner {
            background-color: #de1468;
            border-radius: 50%;
            width: 8px;
            height: 8px;
          }
        }

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: -0.045em;
          color: #000000;

          span {
            color: #de1468;
          }
        }
      }

      button {
        width: 100%;
        max-width: 334px;
        height: 44px;
        border-radius: 22px;
        border: none;
        background: #de1468;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0em;
        color: #ffffff;
        cursor: pointer;

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    .wrapper {
      .secondWrapper {
        .plans {
          gap: 20px;
          justify-content: center;
          .plan {
          }

          .active {
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 20px 0px;
    .wrapper {
      h2 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px;
      }

      .mainText {
        font-size: 14px;
        max-width: 250px;
        width: 100%;
        line-height: 18px;
        margin-bottom: 25px;
      }

      .secondWrapper {
        .plans {
          gap: 10px;
          align-items: center;
          justify-content: center;

          .plan {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
