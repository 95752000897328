.container {
  display: flex;
  align-items: center;
  gap: 15px;
  transform: translateY(7px);
  width: 15%;
  position: relative;

  p {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0em;
    color: #4f4f4f;
  }

  .arrow {
    order: 3;
    display: none;
    position: absolute;
    left: 0;
    img {
      width: 16px;
      height: 10px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .container {
    gap: 10px;
    min-width: 140px;

    p {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    transform: unset;
    justify-content: space-between;

    .switch {
      order: 2;
      margin-left: 30px;
    }
    
    p {
      order: 1;
      font-size: 16px;
      font-weight: 600;
    }

    .arrow {
      display: block;
    }
  }
}
