.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 530px;

  h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: right;
    color: #000000;
  }

  .appointments {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 10px;
  }

  .btn {
    width: 187px;
    height: 44px;
    border-radius: 22px;
    border: none;
    background: #270c66;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    color: #ffffff;
    cursor: pointer;
    align-self: flex-end;
  }
}
