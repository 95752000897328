.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;

    input,
    textarea {
      border: 1px solid rgb(209, 213, 219/1);
      background-color: #fff;
      border-radius: 3px;
      padding: 10px;
      flex-grow: 1;
    }

    input {
      height: 40px;
      flex-basis: 45%;
    }
    textarea {
      height: 70px;
      flex-basis: 100%;
      resize: none;
    }
  }
  button {
    background-color: rgb(17, 24, 39/1);
    border-radius: 5px;
    border: none;
    color: rgb(255, 255, 255/1);
    width: 100%;
    height: 40px;
    font-size: 16px;
    cursor: pointer;
  }
}
