.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 530px;

  h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: right;
    color: #000000;
  }

  .cards {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    .card {
      width: 140px;
      height: 140px;
      border-radius: 22px;
      background: #ffffff;
      box-shadow: 0px 4px 34px 0px #0000001a;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
      cursor: pointer;

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        color: #000000;
        max-width: 100px;
        text-align: center;
      }
    }
  }
}
