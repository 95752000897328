.container {
  width: 406px;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  padding: 15px 20px 20px 15px;
}

@media screen and (max-width: 480px) {
  .container {
    width: 100%;
  }
}