.container {
  background: #de1468;
  color: white;
  border-radius: 9px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .container {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
}
