.mainContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  padding: 20px;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999999999;

  .backdrop {
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    backdrop-filter: brightness(60%);
    -webkit-backdrop-filter: brightness(60%);
    z-index: 99999999999999999;
  }

  .container {
    z-index: 999999999999999999999999999999999999999999999999999999999999;
    border-radius: 22px;
    padding: 20px 20px;
    margin: 0px 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 1);
    min-height: 220px;
    max-height: 90vh;
    width: 100%;
    max-width: 560px;
    overflow-y: auto;
    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #de1468;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      cursor: pointer;
    }
    .content {
      width: 100%;
      height: 100%;
      min-height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 25px;

      input {
        width: 100%;
        max-width: 337px;
        height: 48px;
        border-radius: 12px;
        background: #f9f9f9;
        border: 1px solid #d3d3d3;
        color: #a7a7a7;
        padding: 0px 20px;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0em;
        transition: all 250ms ease-in-out;
      }

      button {
        width: 100%;
        max-width: 337px;
        height: 48px;
        padding: 10px 10px;
        border: none;
        background: rgba(208, 2, 84, 1);
        border-radius: 50px;
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        &:hover {
          opacity: 0.9;
        }
        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .mainContainer {
    align-items: center;
    z-index: 999999;
    .container {
      max-width: 500px;
      border-radius: 20px;
      height: auto;
      min-height: auto;
    }
  }
}
