.mainContainer {
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 99999999999999;

  .container {
    z-index: 999999999999;
    width: 278px;
    height: 100vh;
    box-shadow: 0px 14px 34px rgba(162, 162, 165, 0.4);
    padding: 32px 0px;
    background-color: #ffffff;
    .wrapper {
      display: flex;
      flex-direction: column;
      padding: 20px;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 0px;
        margin-bottom: 30px;

        .logo {
          width: 50px;
          height: 50px;
        }
      }

      nav {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0px 10px 0px 0px;
        li {
          list-style: none;
          display: flex;
          align-items: center;
          padding: 10px 10px;
          border-radius: 5px;
          cursor: pointer;
          gap: 10px;
          img {
            margin-right: 12px;
          }

          p {
            color: var(--brand-text-color);
            font-size: 16px;
          }
        }
        .active {
          background-color: #29292923;

          p {
            color: #000000;
            font-weight: 500;
          }
        }
      }
    }
  }
}

