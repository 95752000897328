.container {
  display: flex;
  flex-direction: column;

  .item {
    border: 1px solid #e5e7eb;
    border-bottom: none;
    padding: 20px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      flex-direction: column;

      p {
        color: #000000;
        font-weight: 400;
        font-size: 14px;
      }
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom: 1px solid #e5e7eb;
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
  }
}
