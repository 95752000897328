.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 0px 0px 30px 0px;

  .wrapper {
    width: 100%;
    max-width: 1600px;
    display: flex;

    .main {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 0px 20px;
      gap: 10px;

      .right {
        display: flex;
        flex-direction: column;
        width: 85%;

        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 30px;
        }
      }

      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 30px;
        width: 15%;
      }
    }
  }
}

@media screen and (max-width: 1340px) {
  .container {
    .wrapper {
      .main {
        flex-direction: column;
        gap: 30px;

        .right {
          width: 100%;
        }

        .left {
          width: 100%;
          flex-direction: row;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .container {
    .wrapper {
      .main {
        .right {
          .row {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    margin-top: 30px;
    .wrapper {
      flex-direction: column;
      align-items: flex-start;

      .main {
        justify-content: flex-start;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .wrapper {
      .main {
        gap: 20px;
        .left {
          gap: 20px;
        }
      }
    }
  }
}
