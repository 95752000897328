.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 297px;
  border-radius: 22px;
  padding: 15px 15px 20px 15px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  position: relative;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
    h3 {
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      color: #000000;
      font-size: 17px;
      align-self: flex-start;
      span {
        color: #de1468;
      }
    }
    .questionMark {
      width: 22px;
      height: 22px;
      background-color: #ffffff;
      border-radius: 50%;
      border: 1px solid #de1468;
      box-shadow: 0px 4px 4px 0px #00000040;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0em;
      color: #000000;
      cursor: default;
    }
  }

  .tooltip {
    position: absolute;
    top: 50px;
    transform: translateX(-200px);
    width: 230px;
    height: 38px;
    background: #060a35;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 15px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;

      span {
        color: #de1468;
      }
    }
  }

  hr {
    border: 1px solid #d2d2d2;
    width: 100%;
  }

  ul {
    width: 100%;
    margin-bottom: 15px;
    li {
      list-style-type: none;
      display: flex;
      align-items: center;
      gap: 10px;
      border-bottom: 1px solid #efefef;
      padding: 10px;

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        color: #4f4f4f;
      }
    }
  }

  .btns {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    button {
      width: 100%;
      height: 37px;
      border-radius: 22px;
      border: none;
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      &:nth-child(1) {
        background: #06c3ff;
      }
      &:nth-child(3) {
        background: #270c66;
      }
      &:nth-child(2) {
        background: #e50000;
      }
    }
  }
}

.disabled {
  background: #fed6d6;
}

@media screen and (max-width: 480px) {
  .container {
  }
}
