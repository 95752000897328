.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;

  .logo {
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
  }

  .card {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: auto;
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
    padding: 35px;

    h1 {
      color: rgb(17, 17, 17/1);
      text-align: center;
      margin-bottom: 25px;
    }
    .field {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      label {
        margin-bottom: 8px;
        color: rgb(136, 136, 136/1);
        font-size: 14px;
      }
      input {
        border: 1px solid rgb(209, 213, 219/1);
        height: 40px;
        border-radius: 3px;
        padding: 10px;
      }
    }

    button {
      width: 100%;
      height: 40px;
      border: none;
      border-radius: 3px;
      color: rgb(255, 255, 255/1);
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      background-color: rgb(17, 24, 39/1);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .card {
      padding: 20px 15px;

      h1 {
        font-size: 26px;
      }
    }
  }
}
