.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;

  .card {
    width: 238px;
    height: 192px;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      margin-bottom: 12px;
      width: 67.13px;
      height: 67.13px;
    }

    h3 {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      color: #ffffff;
      margin-bottom: 7px;
    }

    hr {
      width: 60px;
      border: 1px solid #ffffff;
      margin-bottom: 8px;
    }

    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0em;
      color: #ffffff;
    }

    &:nth-child(1) {
      background-color: #6d14de;
    }
    &:nth-child(2) {
      background-color: #1428de;
    }
    &:nth-child(3) {
      background-color: #14bade;
    }
    &:nth-child(4) {
      background-color: #de8114;
    }
    &:nth-child(5) {
      background-color: #de1468;
    }
  }
}


@media screen and (max-width: 540px) {
  .container {
    justify-content: center;
  }
}