.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
  padding: 0px 20px;

  .wrapper {
    height: 54px;
    width: 100%;
    max-width: 1600px;
    border-radius: 22px;
    border: 1px solid #de1468;
    background: rgba(208, 2, 84, 0.21);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 0px 15px;

    .right {
      display: flex;
      align-items: center;
      gap: 12px;

      p {
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        color: #151515;
      }

      .imgs {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          cursor: pointer;
        }
      }
    }

    .left {
      .close {
        width: 33px;
        height: 33px;
        border: 1px solid #de1468;
        background-color: #ffffff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #de1468;
        font-size: 20px;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .wrapper {
      .right {
        p {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .container {
    .wrapper {
      .right {
        p {
          display: none;
        }
      }
    }
  }
}
