.container {
  z-index: 999999999999999;

  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: #0a0a0a;
    margin-bottom: 20px;
  }

  .filter {
    margin-bottom: 40px;
    .calCard {
      width: 517px;
      height: 428px;
      border-radius: 22px;
      padding: 7px;
      box-shadow: 0px 4px 34px 0px #0000001a;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .noRecord {
    height: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
  }

  .appointments {
    .list {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .filter {
      .calCard {
        width: 100%;
        max-width: 517px;
        height: 400px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .filter {
      .calCard {
        height: 350px;
      }
    }
  }
}
