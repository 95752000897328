.container {
  width: 100%;
  max-width: 513px;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    color: #0a0a0a;
    margin-bottom: 10px;
  }

  .mainText {
    width: 100%;
    max-width: 326px;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #0a0a0a;
    margin-bottom: 30px;
  }

  textarea {
    width: 100%;
    height: 150px;
    border-radius: 12px;
    background: #f9f9f9;
    border: 1px solid #de1468;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0em;
    padding: 15px;
    margin-bottom: 6px;
    resize: none;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      color: #0a0a0a;
    }
  }

  .check {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;

    .block {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: right;
        color: #0a0a0a;
      }
    }
  }

  button {
    width: 100%;
    max-width: 326px;
    height: 52px;
    border-radius: 22px;
    border: none;
    background: #de1468;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    color: #ffffff;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .remove {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      color: #e50000;
    }
  }
}

@media screen and (max-width: 540px) {
  .container {
    .check {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 30px 20px;
    h2 {
      font-size: 18px;
      margin-bottom: 5px;
    }
    .mainText {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 20px;
    }
    textarea {
      height: 130px;
      font-size: 14px;
      padding: 12px;
      margin-bottom: 4px;
    }
    .row {
        margin-bottom: 20px;
      p {
        font-size: 14px;
      }
    }
    .check {
        margin-bottom: 40px;
    }
  }
}
