.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 0px 0px 30px 0px;

  .wrapper {
    width: 100%;
    max-width: 1640px;
    display: flex;
    z-index: 99;
    padding: 0px 20px;

    .main {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 50px;

      h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0em;
        color: #000000;
        margin-bottom: 30px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .container {
    .wrapper {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
