.container {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .cardContainer {
    .block {
      margin-bottom: 20px;
      .title {
        display: flex;
        align-items: baseline;
        gap: 10px;
        margin-bottom: 5px;
        h4 {
          font-size: 19px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0em;
          color: #0a0a0a;
        }

        .tag {
          height: 17px;
          padding: 2px 14px 3px 14px;
          border-radius: 22px;
          background: #06a816;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0em;
            color: #ffffff;
          }
        }
        .tag2 {
          background: #fa3646;
        }
        .tag3 {
          background: #ffcf00;
          p {
            color: #000000;
          }
        }
      }

      .textOne {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        color: #0a0a0a;
        margin-bottom: 12px;
      }

      .field {
        display: flex;
        flex-direction: column;

        label {
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          color: #0a0a0a;
          margin-bottom: 10px;
        }
        .specialLabel {
          color: #d00254;
          font-weight: 600;
        }
        textarea,
        select,
        input {
          width: 100%;
          border-radius: 12px;
          background: #f9f9f9;
          border: 1px solid #d3d3d3;
          padding: 0px 15px;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0em;
          // color: #afafaf;
          color: #4f4f4f;
          transition: all 250ms ease-in-out;

          &:focus,
          &:active {
            box-shadow: 0px 4px 34px 0px #0000001a;
            border: 1px solid #de1468;
          }
        }

        select {
          height: 48px;
          /** for the dropdown indicator */
          appearance: none;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-position: left 0.8rem center;
          background-size: 1em;
          option {
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0em;
            color: #4f4f4f;
          }
        }
        input {
          height: 48px;
        }
        textarea {
          height: 98px;
          padding: 12px 15px;
          resize: none;
          margin-bottom: 5px;
        }
        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            color: #0a0a0a;
          }
        }
      }
    }
    .lastBlock {
      margin-bottom: 0px;
    }
    .sync {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      padding: 10px 0px;

      .title {
        display: flex;
        align-items: center;
        gap: 10px;

        .tag {
          height: 17px;
          padding: 2px 14px 3px 14px;
          border-radius: 22px;
          background: #06a816;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0em;
            color: #ffffff;
          }
        }

        h3 {
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0em;
          color: #0a0a0a;
        }
      }

      button {
        width: 100%;
        height: 48px;
        border-radius: 22px;
        border: none;
        background: #4286f5;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0em;
          color: #ffffff;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .cardContainer {
      .block {
        .title {
          h4 {
            font-size: 16px;
          }
          .tag {
            height: 17px;
            padding: 2px 8px 3px 8px;

            p {
              font-size: 10px;
              line-height: 16px;
            }
          }
        }
      }
      .sync {
        .title {
          gap: 8px;

          .tag {
            height: 17px;
            padding: 2px 8px 3px 8px;

            p {
              font-size: 10px;
              line-height: 16px;
            }
          }

          h3 {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
