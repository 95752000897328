.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 0px 0px 30px 0px;

  .wrapper {
    width: 100%;
    max-width: 1600px;
    display: flex;

    .main {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
      padding: 0px 20px;
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    margin-top: 30px;
    .wrapper {
      flex-direction: column;
      align-items: flex-start;

      .main {
        justify-content: flex-start;
        flex-wrap: wrap;
      }
    }
  }
}
