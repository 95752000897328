.container {
  width: 100%;
  max-width: 657px;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  padding: 30px 30px 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: -95px;

    .icon {
      width: 25px;
      height: 25px;
      position: absolute;
      border-radius: 50%;
      box-shadow: 0px 2.5964913368225098px 22.07017707824707px 0px #0000001a;

      img {
        width: 25px;
        height: 25px;
        border: 0.65px solid #de1468;
        background-color: #ffffff;
        border-radius: 50%;
        padding: 4px;
      }

      input {
        position: absolute;
        width: 25px;
        height: 25px;
        opacity: 0;
        top: 0;
        left: 0;
      }
    }
    .cover {
      width: 100%;
      position: relative;
      height: 199px;

      .img {
        width: 100%;
        height: 199px;
        object-fit: cover;
        border-radius: 22px;
      }
      .icon {
        top: 12px;
        left: 12px;
      }
    }

    .main {
      transform: translateY(-115px);
      width: 173px;
      height: 170px;
      border-radius: 100px;
      background: #ffffff;
      border: 2px solid #ffffff;
      box-shadow: 0px 4px 34px 0px #0000001a;
      position: relative;

      .img {
        border-radius: 100px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .icon {
        bottom: 0px;
        left: 27px;
      }
    }
  }

  .body {
    width: 100%;
    max-width: 494px;
    .name {
      font-size: 24px;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: 0em;
      color: #000000;
      text-align: center;
      margin-bottom: 25px;
    }

    .daysBtn {
      width: 100%;
      max-width: 494px;
      height: 44px;
      border-radius: 22px;
      border: none;
      background: #270c66;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      color: #ffffff;
      cursor: pointer;
      margin-bottom: 30px;
    }

    .check {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 30px;

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        color: #000000;
      }
    }

    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 35px;
      h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0em;
        color: #0a0a0a;
        margin-bottom: 15px;
      }
      textarea {
        width: 100%;
        height: 100px;
        border-radius: 12px;
        background: #f9f9f9;
        border: 1px solid #de1468;
        resize: none;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0em;
        color: #afafaf;
        padding: 10px 15px;
        margin-bottom: 8px;
      }
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          color: #0a0a0a;
        }
      }
    }

    .form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 25px;
      .field {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
        width: 47%;

        label {
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0em;
          color: #0a0a0a;

          span {
            color: #de1468;
          }
        }

        input {
          width: 100%;
          height: 48px;
          border-radius: 12px;
          background: #f9f9f9;
          border: 1px solid #d3d3d3;
          color: #a7a7a7;
          padding: 0px 20px;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0em;
          transition: all 250ms ease-in-out;

          &:focus,
          &:active {
            background: #ffffff;
            border: 1px solid #de1468;
            box-shadow: 0px 4px 34px 0px #0000001a;
          }
        }
      }
    }

    .services {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0em;
        color: #0a0a0a;
        text-align: center;
        margin-bottom: 20px;
      }
      .items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        .item {
          width: auto;
          height: 34px;
          border-radius: 22px;
          background: #ffffff;
          border: 1px solid #dadada;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          padding: 0px 8px;

          p {
            font-size: 15px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            color: #c3c3c3;
          }
        }
        .active {
          border: 1px solid #de1468;
          background: rgba(222, 20, 104, 0.15);
          p {
            color: #de1468;
          }
        }
      }
    }

    .workingHours {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      width: 100%;
      h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0em;
        color: #0a0a0a;
        text-align: center;
        margin-bottom: 20px;
      }

      .items {
        display: flex;
        flex-direction: column;
        width: 100%;

        .item {
          width: 100%;
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          border-bottom: 1px solid #dedede;
          padding: 15px 0px;

          h3 {
            font-weight: 600;
            color: #0a0a0a;
          }

          .timing {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .hours {
              display: flex;
              align-items: center;
              gap: 10px;
              p {
                color: #0a0a0a;
              }
              img {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }

    .submit {
      width: 100%;
      height: 52px;
      border-radius: 22px;
      border: none;
      background: #de1468;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1100px) {
  .container {
    .body {
      .form {
        width: 100%;
        .field {
          width: 100%;
          input {
            max-width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .container {
    .body {
      .form {
        width: 100%;
        .field {
          width: 47%;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .container {
    .body {
      .check {
        align-items: flex-start;
        p {
          transform: translateY(-3px);
          font-size: 14px;
          line-height: 18px;
        }
      }
      .form {
        width: 100%;
        .field {
          width: 100%;
          input {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 20px;

    .profile {
      margin-bottom: -65px;
      .main {
        width: 130px;
        height: 130px;
        transform: translateY(-80px);
        .icon {
          left: 18px;
        }
      }
    }
    .body {
      .name {
        font-size: 20px;
        margin-bottom: 15px;
      }
      .daysBtn {
        height: 36px;
        font-size: 14px;
        margin-bottom: 25px;
      }
      .check {
        margin-bottom: 15px;
        p {
          font-size: 12px;
          line-height: 15px;
        }
      }
      .desc {
        margin-bottom: 15px;
        h4 {
          font-size: 18px;
          margin-bottom: 8px;
        }
        textarea {
          font-size: 14px;
          margin-bottom: 4px;
        }
        .row {
          p {
            font-size: 12px;
          }
        }
      }
      .form {
        gap: 10px;
        margin-bottom: 15px;
        .field {
          gap: 6px;
          margin-bottom: 5px;
          label {
            font-size: 16px;
          }
          input {
            font-size: 14px;
          }
        }
      }
      .services {
        margin-bottom: 20px;

        h4 {
          font-size: 18px;
          margin-bottom: 15px;
        }
      }
      .submit {
        height: 38px;
        font-size: 14px;
      }
    }
  }
}
