.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 0px 0px 30px 0px;

  .wrapper {
    width: 100%;
    max-width: 1640px;
    display: flex;
    flex-direction: column;
    z-index: 99;
    padding: 0px 20px;

    .add {
      width: 211px;
      height: 48px;
      border-radius: 22px;
      background: #ffffff;
      box-shadow: 0px 4px 34px 0px #0000001a;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      cursor: pointer;
      margin-bottom: 45px;

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        color: #000000;
      }
    }

    .active {
      border: 1px solid #de1468;
      box-shadow: 0px 4px 34px 0px #0000001a;
      p {
        color: #de1468;
      }
    }

    .main {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .container {
    .wrapper {
      flex-direction: column;
      align-items: flex-start;

      .add {
        align-self: center;
      }

      .main {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 40px;
      }
    }
  }
}
