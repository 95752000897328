.container {
  // width: 75%;
  width: 100%;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  padding: 20px 60px 20px 20px;
  overflow-x: auto;

  h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: #0a0a0a;
    margin-bottom: 20px;
  }

  .tableWrapper {
    table {
      min-width: 1000px;
      width: 100%;
      text-align: right;
      border-spacing: 0px;

      thead {
        tr {
          th {
            padding: 15px 0px;
            border-bottom: 1px solid #d2d2d2;
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            color: #0a0a0a;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 15px 0px;
            font-size: 16px;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0em;
            color: #140635;
            .row {
              display: flex;
              align-items: center;
              gap: 5px;
              white-space: nowrap;
              cursor: pointer;
            }
            button {
              width: 123px;
              height: 27px;
              border-radius: 32px;
              font-size: 16px;
              font-weight: 600;
              line-height: 21px;
              letter-spacing: 0em;
              text-align: center;
              cursor: pointer;
            }
            .red {
              background: rgba(255, 182, 182, 0.36);
              border: 1px solid #e40000;
              color: #e40000;
            }
            .green {
              background: rgba(182, 255, 189, 0.36);
              border: 1px solid #06a816;
              color: #06a816;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .container {
    padding: 20px 30px 20px 20px;
  }
}

@media screen and (max-width: 1100px) {
  .container {
    width: 100%;
    .tableWrapper {
      table {
        thead {
          tr {
            th {
              font-size: 14px;

              &:nth-child(1) {
                width: 25% !important;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 14px;
              &:nth-child(1) {
                width: 25% !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .tableWrapper {
      table {
        thead {
          tr {
            th {
              width: 15% !important;

              &:nth-child(1) {
                width: 15% !important;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              width: 15% !important;

              &:nth-child(1) {
                width: 15% !important;
              }
            }
          }
        }
      }
    }
  }
}
