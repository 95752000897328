.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 515px;
  padding: 30px;
  border-radius: 22px;
  box-shadow: 0px 4px 34px 0px #0000001a;
  background: #ffffff;

  .wrapper {
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      font-size: 24px;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: 0em;
      color: #0a0a0a;
    }
    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: left;
      color: #0a0a0a;
      margin-bottom: 10px;
    }

    .mainText {
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
      color: #0a0a0a;
      margin-bottom: 25px;
    }

    .remaining {
      width: 100%;
      height: 143px;
      border-radius: 22px;
      background: #270c66;
      box-shadow: 0px 4px 34px 0px #0000001a;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      margin-bottom: 40px;

      h3 {
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: 0em;
        color: #ffffff;
        margin-bottom: 12px;
      }

      hr {
        width: 60px;
        border: 1px solid #ffffff;
        margin-bottom: 12px;
      }
      p {
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0em;
        color: #ffffff;
        span {
          font-weight: 700;
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 35px;
      h4 {
        margin-bottom: 20px;
      }
      .btns {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        button {
          width: 100%;
          max-width: 241px;
          height: 44px;
          border-radius: 22px;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          cursor: pointer;

          &:nth-child(1) {
            color: #ffffff;
            background: #de1468;
            border: 1px solid #de1468;
          }
          &:nth-child(2) {
            color: #060a35;
            background: transparent;
            border: 1px solid #060a35;
          }
        }
      }
    }
    .calendar {
      margin-bottom: 25px;
      margin-top: 20px;
      width: calc(100% + 100px);
    }
    .types {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 15px;
      h4 {
        margin-bottom: 20px;
      }
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        .type {
          width: 100%;
          max-width: 164px;
          height: 281px;
          border-radius: 22px;
          padding: 20px 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #ffffff;
          border: 1px solid #ffffff;
          box-shadow: 0px 4px 34px 0px #0000001a;
          border-radius: 22px;

          .iconContainer {
            height: 65px;
            svg {
              margin-bottom: 10px;
            }
          }
          h5 {
            font-size: 20px;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: 0em;
            margin-bottom: 15px;
            color: #0a0a0a;
          }
          p {
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: center;
            margin-bottom: 7px;
            color: #0a0a0a;
          }
          small {
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: center;
            color: #de1468;
            margin-bottom: 18px;
          }
          button {
            width: 100%;
            max-width: 125px;
            height: 44px;
            border-radius: 22px;
            font-size: 16px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: center;
            background: transparent;
            border: 1px solid #270c66;
            color: #270c66;
            cursor: pointer;
          }
        }
        .active {
          background: #fbeaf1;
          border: 1px solid #de1468;
          h5 {
            color: #de1468;
          }
          button {
            background: #de1468;
            border: 1px solid #de1468;
            color: #ffffff;
          }
        }
      }
    }

    .message {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 6px;
      h4 {
        margin-bottom: 20px;
      }

      textarea {
        width: 100%;
        height: 150px;
        border-radius: 12px;
        background: #f9f9f9;
        border: 1px solid #de1468;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0em;
        padding: 15px;
        resize: none;
      }
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 40px;

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        color: #0a0a0a;
      }
    }
    .submitBtn {
      width: 100%;
      max-width: 326px;
      height: 52px;
      border-radius: 22px;
      border: none;
      background: #de1468;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0em;
      color: #ffffff;
      margin-bottom: 20px;
      cursor: pointer;

      &:disabled {
        opacity: 0.8;
        cursor: not-allowed;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .wrapper {
      .calendar {
        margin-bottom: 25px;
        margin-top: 10px;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .container {
    padding: 30px 20px;
    .wrapper {
      h4 {
        font-size: 20px;
      }
      h2 {
        font-size: 20px;
        margin-bottom: 5px;
      }
      .mainText {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 20px;
      }
      .actions {
        h4 {
          margin-bottom: 15px;
        }
      }
      .types {
        margin-bottom: 5px;
        h4 {
          margin-bottom: 15px;
        }
        .row {
          gap: 10px;
          .type {
            height: auto;
            max-width: auto;
            width: 48%;
            padding: 20px 10px;
            h5 {
              font-size: 18px;
              margin-bottom: 10px;
            }
            p {
              font-size: 13px;
              line-height: 17px;
              margin-bottom: 5px;
            }
            small {
              margin-bottom: 15px;
              font-size: 14px;
            }
            button {
              height: 38px;
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
      .message {
        margin-bottom: 3px;
        h4 {
          margin-bottom: 15px;
        }
        textarea {
          font-size: 14px;
          height: 130px;
        }
      }
      .row {
        margin-bottom: 25px;
        p {
          font-size: 12px;
        }
      }
      .submitBtn {
        height: 44px;
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }
}
