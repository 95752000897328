.container {
  z-index: 999;
  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: #0a0a0a;
    margin-bottom: 20px;
  }

  .nearest {
    margin-bottom: 40px;
    width: 530px;
    display: flex;
    flex-direction: column;
  }
  
  .noRecord {
    height: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
  }

  .appointments {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 530px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    .nearest {
      width: auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
  }
}
