.container {
  width: 100%;
  height: 59px;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  display: flex;
  align-items: center;
  padding: 0px 50px;
  margin-bottom: 40px;

  ul {
    display: flex;
    align-items: center;
    gap: 60px;

    li {
      list-style: none;
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0em;
      color: #000000;
      cursor: pointer;
    }
    .active {
      color: #de1468;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0px 30px;
    ul {
      gap: 40px;
      li {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 0px 20px;
    ul {
      gap: 30px;
      li {
        font-size: 14px;
      }
    }
  }
}
