.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
  width: 100%;
  max-width: 206px;
  height: 137px;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;

  h4 {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: #0a0a0a;
  }

  button {
    width: 172px;
    height: 44px;
    border-radius: 22px;
    background: transparent;
    border: 1px solid #270c66;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: #270c66;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .container {
    h4 {
      font-size: 18px;
      line-height: 22px;
    }
    button {
      width: 130px;
      height: 35px;
      font-size: 14px;
      line-height: 0px;
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    h4 {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
