.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 530px;

  h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: right;
    color: #000000;
  }

  .card {
    display: flex;
    flex-direction: column;
    width: 373px;
    height: auto;
    border-radius: 22px;
    background: #ffffff;
    box-shadow: 0px 4px 34px 0px #0000001a;
    padding: 20px;

    h3 {
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0em;
      color: #0a0a0a;
      margin-bottom: 12px;
    }

    .smallCards {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: 82px;
      border-radius: 12px;
      background: #fafafa;
      margin-bottom: 20px;
      span {
        height: 21px;
        border: 1px solid #dadada;
      }
      .smallCard {
        display: flex;
        flex-direction: column;
        align-items: center;
        h4 {
          font-size: 22px;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: 0em;
          color: #d00254;
        }
        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: center;
          color: #000000;
        }
      }
    }

    .subCard {
      width: 100%;
      height: 205px;
      border-radius: 12px;
      background: #fafafa;
      padding: 20px;
      margin-bottom: 20px;

      .text {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        color: #0a0a0a;
        gap: 20px;
      }

      .chartContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .right {
          small {
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            color: #0a0a0a;
            position: absolute;
            bottom: 20px;
            right: 2px;

            span {
              color: #de1468;
              font-weight: 700;
            }
          }
        }
        .left {
          .chart {
            :global(.apexcharts-legend) {
              display: flex !important;
              flex-direction: column !important;
              gap: 5px !important;
              transform: translateX(21px);
            }

            :global(.apexcharts-legend-series) {
              display: flex !important;
              align-items: center !important;
              gap: 7px !important;
            }

            :global(.apexcharts-legend-text) {
              font-size: 14px !important;
              font-weight: 500 !important;
              line-height: 18px !important;
              letter-spacing: 0em !important;
              color: #000000 !important;
            }
          }
        }
      }
    }

    .copyCard {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      border-radius: 20px;
      border: 1px dashed #000;

      .text {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        max-width: 280px;
        color: #0a0a0a;
        margin-bottom: 10px;
      }

      .copy {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background: #fafafa;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        cursor: pointer;

        p {
          color: #270c66;
          font-weight: 600;
        }
        img {
          width: 15px;
          height: 15px;
        }
      }

      .copied {
        color: #06a816;
        margin-bottom: 10px;
        text-align: center;
        font-size: 14px;
      }

      button {
        background-color: #c7cede;
        width: 100%;
        border: none;
        border-radius: 7px;
        font-size: 16px;
        font-weight: 700;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        cursor: pointer;
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    max-width: 100%;
    .card {
      width: 100%;
    }
  }
}

@media screen and (max-width: 400px) {
  .container {
    max-width: 100%;
    .card {
      width: 100%;
      .subCard {
        height: auto;
        .chartContainer {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          overflow: hidden;
          .right {
            order: 2;
            small {
              position: unset;
            }
          }
          .left {
            overflow: hidden;
            margin-bottom: 10px;
            .chart {
              :global(.apexcharts-legend) {
                transform: translateX(15px);
              }
              
              :global(.apexcharts-inner) {
                border: 1px solid #000 !important;
              }
            }
          }
        }
      }
    }
  }
}
