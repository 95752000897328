.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  width: 100%;
  height: 300px;

  svg {
    margin-bottom: 15px;
  }

  h2 {
    font-size: 32px;
    line-height: 41.86px;
    font-weight: 600;
    color: #0a0a0a;
    width: 100%;
    max-width: 300px;
    text-align: center;
    margin-bottom: 35px;
  }

  .items {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    svg {
      cursor: pointer;
      width: 60px;
      height: 60px;
      transition: all 250ms ease-in-out;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    height: 200px;
    svg {
      width: 75px;
      height: 75px;
    }
    h2 {
      font-size: 28px;
      line-height: 32px;
    }
    .items {
      gap: 20px;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}
