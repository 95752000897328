.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px 35px 0px;

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    color: #0a0a0a;
    text-align: center;
    margin-bottom: 10px;
  }

  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    color: #0a0a0a;
    text-align: center;
    width: 100%;
    max-width: 326px;
    margin-bottom: 30px;
  }

  .imgInputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 50px;

    h4 {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: center;
      color: #0a0a0a;
    }
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    width: 100%;
    max-width: 484px;

    .field {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      max-width: 224px;

      &:nth-child(5) {
        width: 100%;
        max-width: 100% !important;

        input {
          width: 100%;
        }
      }

      label {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
        color: #0a0a0a;

        span {
          color: #de1468;
        }
      }

      input {
        width: 100%;
        height: 48px;
        border-radius: 12px;
        background: #f9f9f9;
        border: 1px solid #d3d3d3;

        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0em;
        color: #a7a7a7;
        padding: 0px 17px;
        transition: all 250ms ease-in-out;

        &::placeholder {
          color: #c1c0c0;
        }

        &:active,
        &:focus {
          border: 1px solid #de1468;
          box-shadow: 0px 4px 34px 0px #0000001a;
        }
      }
    }

    button {
      width: 100%;
      min-width: 100%;
      padding: 0px 20px;
      height: 44px;
      border-radius: 22px;
      background-color: #de1468;
      border-radius: 22px;
      border: none;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
      cursor: pointer;

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-bottom: 20px;
    .form {
      gap: 10px;
      .field {
        width: 48%;
        gap: 5px;

        label {
          font-size: 16px;
        }
      }
      button {
        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding-bottom: 10px;

    h2 {
        font-size: 22px;
    }
    .text {
        font-size: 14px;
        line-height: 18px;
        max-width: 300px;
        margin-bottom: 15px;
    }
    .imgInputContainer {
        margin-bottom: 25px;

        h4 {
            font-size: 16px;
        }
    }
    .form {
      gap: 7px;
      .field {
        width: 100%;
        max-width: 100%;
        gap: 5px;

        label {
          font-size: 14px;
        }
        input {
            font-size: 14px;
            height: 42px;
        }
      }
      button {
        font-size: 16px;
        height: 42px;
      }
    }
  }
}
