.container {
  width: calc(50% - 15px);
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  cursor: pointer;
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    height: 98px;
    padding: 20px 20px;
    position: relative;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .right {
      display: flex;
      align-items: center;
      gap: 20px;
      .name {
        display: flex;
        flex-direction: column;
        gap: 2px;
        h3 {
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0em;
          color: #0a0a0a;
        }
        p {
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: #000000;
        }
      }
    }

    .left {
      display: flex;
      align-items: center;
      gap: 20px;
      .edit {
        display: flex;
        align-items: center;
        gap: 5px;

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0em;
          color: #4f4f4f;
        }
      }
    }
  }

  .body {
    transition: all 250ms ease-in-out;
    padding: 0px 20px;

    .line1 {
      border: 1px solid #d2d2d2;
    }

    ul {
      li {
        list-style: none;
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 14px 0px;
        border-bottom: 1px solid #f1f1f1;
        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }

        p {
          font-size: 20px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0em;
          color: #000000;
        }
      }
    }
  }
  .open {
    height: 392px;
  }
  .hide {
    height: 0px;
  }
}

@media screen and (max-width: 1100px) {
  .container {
    width: 100%;
    max-width: 530px;
    min-width: 460px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    .header {
      height: 90px;
    }
    .body {
      padding: 0px 20px;

      ul {
        li {
          padding: 14px 0px;
          p {
            font-size: 16px;
            line-height: 24px;
          }
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .open {
      height: 380px;
    }
  }
}

@media screen and (max-width: 540px) {
  .container {
    min-width: 100%;
    max-width: 100%;
    .header {
      height: auto;
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .header {
      padding: 15px 20px;
      .right {
        gap: 10px;
        .name {
          h3 {
            font-size: 15px;
            line-height: 21px;
            max-width: 100px;
          }
          p {
            font-size: 12px;
          }
        }
      }

      .left {
        gap: 10px;
        .edit {
          p {
            font-size: 12px;
          }
          img {
            width: 15px;
            height: 15px;
          }
        }
        .arrow {
          img {
            width: 14px;
            height: 8px;
          }
        }
      }
    }
    .body {
      ul {
        li {
          p {
            font-size: 15px;
            line-height: 23px;
          }
        }
      }
    }
    .open {
      height: 365px;
    }
  }
}
