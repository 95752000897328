.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  width: 100%;
  box-shadow: 0px 4px 34px 0px #0000001a;
  border-radius: 0px 0px 22px 22px;
  padding: 0px 20px;

  .wrapper {
    width: 100%;
    max-width: 1600px;
    display: flex;
    align-items: center;
    .logo {
      margin-left: 15px;
      cursor: pointer;
    }

    nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      width: 100%;

      li {
        display: flex;
        align-items: center;
        gap: 5px;
        list-style-type: none;
        cursor: pointer;
        position: relative;

        .userImg {
          width: 39px;
          height: 39px;
          border-radius: 50%;
          object-fit: cover;
        }

        p {
          font-size: 18px;
          font-weight: 500;
          line-height: 26px;
          letter-spacing: 0em;
          color: #000000;
        }

        .userDropdown {
          box-shadow: 0px 4px 34px 0px #0000001a;
          background-color: #fff;
          position: absolute;
          padding: 15px;
          bottom: -100px;
          left: 0px;
          border-radius: 5px;

          ul {
            display: flex;
            flex-direction: column;
            gap: 10px;

            li {
              display: flex;
              align-items: center;
              gap: 8px;
              cursor: pointer;
              img {
                width: 17px;
                height: 17px;
              }
            }
          }
        }
      }

      .active {
        p {
          color: #DE1468;
        }
      }
    }

    .menu {
      display: none;
    }
  }
}

@media screen and (max-width: 1550px) {
  .header {
    .wrapper {
      nav {
        gap: 10px;
        li {
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .header {
    .wrapper {
      nav {
        gap: 8px;
        li {
          p {
            font-size: 14px;
          }
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .header {
    .wrapper {
      .logo {
        width: 38px;
        height: 35px;
        margin-left: 10px;
      }
      nav {
        gap: 4px;
        li {
          gap: 3px;
          p {
            font-size: 12px;
          }
          svg {
            width: 18px;
            height: 18px;
          }
          img {
            width: 34px;
            height: 34px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .header {
    .wrapper {
      justify-content: space-between;
      .logo {
        width: 38px;
        height: 35px;
        margin-left: 10px;
      }
      nav {
        display: none;
      }
      .menu {
        display: block;
      }
    }
  }
}
