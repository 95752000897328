.container {
  width: 100%;
  direction: ltr !important;

  :global(.rbc-event-label) {
    display: none;
  }

  :global(.rbc-event) {
    padding: 0px;
  }

  .customEvent {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-radius: 5px;
    .title {
      text-align: right;
      font-size: 12px;
      margin-bottom: 2px;
    }

    .hours {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 2px;
      svg {
        width: 12px;
        height: 12px;
      }
      p {
        font-size: 12px;
      }
    }

    .customer {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 2px;
      svg {
        width: 12px;
        height: 12px;
      }
      p {
        font-size: 12px;
      }
    }
  }
}
