.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 0px 0px 30px 0px;

  .wrapper {
    width: 100%;
    max-width: 1640px;
    display: flex;

    .main {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding-left: 20px;

      .top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text {
          display: flex;
          flex-direction: column;

          h1 {
            font-size: 24px;
            font-weight: 700;
            line-height: 31px;
            letter-spacing: 0em;
            color: #0a0a0a;
            margin-bottom: 10px;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            color: #4f4f4f;
          }
        }
        button {
          width: 143px;
          height: 44px;
          border-radius: 22px;
          border: none;
          background: #270c66;
          font-size: 16px;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    margin-top: 30px;
    .wrapper {
      flex-direction: column;
      align-items: flex-start;

      .main {
        padding: 0px 20px;
      }
    }
  }
}
