.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 0px 0px 30px 0px;

  .wrapper {
    width: 100%;
    max-width: 1600px;
    display: flex;

    .main {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0px 20px;

      .history {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
      }
      .loadMore {
        width: 100%;
        max-width: 530px;
        height: 44px;
        border-radius: 22px;
        border: none;
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
        cursor: pointer;
        color: #ffffff;
        background: #de1468;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    margin-top: 30px;
    .wrapper {
      flex-direction: column;
      align-items: flex-start;

      .main {
        justify-content: flex-start;
        transform: unset;
      }
    }
  }
}
