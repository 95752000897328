.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 0px 0px 30px 0px;

  .wrapper {
    width: 100%;
    max-width: 1600px;
    display: flex;

    .main {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;
      padding: 0px 20px;

      .items {
        display: flex;
        flex-direction: column;
        width: 100%;

        .item {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e2e2e2;
          padding: 20px 10px;
          width: 100%;

          .right {
            display: flex;
            flex-direction: column;
            gap: 3px;
            border-left: 1px solid #e2e2e2;
            padding-left: 30px;
            min-width: 160px;
            h3 {
              font-size: 22px;
            }
            p {
              font-size: 18px;
              color: #0a0a0a;
            }
          }

          .left {
            padding-right: 30px;

            p {
              font-size: 20px;
              color: #0a0a0a;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    margin-top: 30px;
    .wrapper {
      flex-direction: column;
      align-items: flex-start;

      .main {
        justify-content: flex-start;
        gap: 20px;

        h1 {
          font-size: 20px;
        }

        .items {
          .item {
            padding: 20px 10px;
            .right {
              gap: 3px;
              padding-left: 30px;
              min-width: 140px;
              h3 {
                font-size: 18px;
                white-space: nowrap;
              }
              p {
                font-size: 16px;
              }
            }

            .left {
              padding-right: 30px;

              p {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .wrapper {
      .main {
        gap: 20px;
        h1 {
          font-size: 20px;
        }
        .items {
          .item {
            padding: 20px 0px;
            .right {
              gap: 3px;
              padding-left: 30px;
              min-width: 100px;
              h3 {
                font-size: 16px;
                white-space: normal;
                max-width: 100px;
              }
              p {
                font-size: 14px;
              }
            }

            .left {
              padding-right: 20px;

              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
