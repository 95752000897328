.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;

  h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: right;
    color: #000000;
  }

  .cubes {
    display: flex;
    align-items: baseline;
    gap: 20px;

    .cube {
      width: 162px;
      height: 140px;
      border-radius: 22px;
      background: #270c66;
      box-shadow: 0px 4px 34px 0px #0000001a;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 14px;

      &:nth-child(1) {
        background: #d00254;
      }

      hr {
        border: 1px solid #ffffff;
        width: 60px;
      }

      h3 {
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: 0em;
        color: #ffffff;
      }
      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    .cubes {
      .cube {
        width: 33.333%;
        max-width: 162px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .cubes {
      gap: 10px;
      .cube {
        height: 115px;
        gap: 9px;
        hr {
          width: 40%;
        }
        h3 {
          font-size: 28px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
}
