.container {
  display: flex;
  align-items: baseline;
  gap: 0px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;

  .column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 85%;
    .row {
      display: flex;
      align-items: baseline;
    }
  }
}
@media screen and (max-width: 1080px) {
  .container {
    .column {
      .row {
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    .column {
      width: 100%;
      overflow: hidden;
      gap: 30px;
    }

    .open {
      height: auto;
      padding: 20px 0px;
    }
    .hide {
      height: 0;
      padding: 0px;
    }
  }
}
