.container {
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;

    .text {
      h1 {
        color: rgb(17, 17, 17/1);
        font-size: 26px;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        color: rgb(136, 136, 136/1);
      }
    }

    button {
      background-color: rgb(17, 24, 39/1);
      border: none;
      border-radius: 8px;
      width: fit-content;
      padding: 0px 20px;
      height: 40px;
      color: rgb(255, 255, 255/1);
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .modalContainer {
    form {
      display: flex;
      flex-direction: column;
      gap: 15px;
      input,
      textarea {
        border: 1px solid rgb(209, 213, 219/1);
        border-radius: 3px;
        padding: 10px;
        flex-grow: 1;
      }
      input {
        height: 40px;
      }
      textarea {
        height: 80px;
        resize: none;
      }

      .countText {
        color: var(--secondary-text-color);
        font-size: 14px;
      }

      .count {
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          background-color: var(--secondary-text-color);
          width: 30px;
          height: 30px;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 20px;
          cursor: pointer;
        }
        input {
          width: 40px;
          height: 30px;
          flex-grow: 0;
          text-align: center;
        }
      }

      button {
        background-color: rgb(17, 24, 39/1);
        border-radius: 5px;
        border: none;
        color: rgb(255, 255, 255/1);
        flex-grow: 1;
        height: 40px;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    .header {
      margin-bottom: 25px;
      .text {
        h1 {
          font-size: 22px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .header {
      .text {
        h1 {
          font-size: 20px;
        }
      }
      button {
        border-radius: 5px;
        height: 35px;
        font-size: 14px;
        padding: 0px 10px;
      }
    }
  }
}
