.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 0px 0px 30px 0px;

  .wrapper {
    width: 100%;
    max-width: 1600px;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0px 20px;

    .card {
      width: 100%;
      max-width: 515px;
      height: auto;
      min-height: 737px;
      border-radius: 22px;
      background: #ffffff;
      border: 1px solid #de1468;
      box-shadow: 0px 4px 34px 0px #0000001a;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px 20px;

      img {
        margin-bottom: 30px;
      }

      h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        color: #0a0a0a;
        margin-bottom: 10px;
      }

      .text1 {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: #0a0a0a;
        margin-bottom: 35px;
      }

      .text2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: #0a0a0a;
        margin-bottom: 18px;
      }

      h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        color: #060a35;
        margin-bottom: 15px;
      }

      .text3 {
        font-size: 24px;
        font-weight: 500;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        color: #0a0a0a;
        margin-top: 20px;
        margin-bottom: 35px;
        span {
          color: #de1468;
        }
      }

      hr {
        width: 100%;
        border: 1px solid #d2d2d2;
        margin-bottom: 30px;
      }

      .text4 {
        font-size: 24px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        color: #de1468;
        text-decoration: underline;
        margin-bottom: 12px;
      }

      small {
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
        color: #0a0a0a;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    margin-top: 30px;
    .wrapper {
      .card {
        min-height: auto;

        img {
          width: 120px;
          height: 120px;
          margin-bottom: 15px;
        }
        h2 {
          font-size: 22px;
          margin-bottom: 8px;
        }
        .text1 {
          font-size: 15px;
          margin-bottom: 20px;
        }
        .text2 {
          font-size: 15px;
          margin-bottom: 15px;
        }
        h3 {
          font-size: 20px;
          margin-bottom: 8px;
        }
        .text3 {
          font-size: 20px;
          margin-top: 8px;
          margin-bottom: 25px;
        }
        hr {
          margin-bottom: 25px;
        }
        .text4 {
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .container {
    .wrapper {
      .card {
        .text1 {
          max-width: 250px;
        }
      }
    }
  }
}
