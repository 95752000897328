.container {
  width: 18%;
  max-width: 160px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  flex-grow: 1;

  .block {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      color: #4f4f4f;
    }
  }
}

@media screen and (max-width: 1400px) {
  .container {
    gap: 8px;
    .block {
      gap: 3px;
      p {
        font-size: 14px;
        line-height: 0px;
      }
      svg,img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .container {
    justify-content: flex-start;
    margin-top: 20px;
  }
}

