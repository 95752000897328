.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-name: fade;
  animation-duration: 800ms;
  animation-iteration-count: 1;
  animation-direction: normal;

  @keyframes fade {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
    color: #0a0a0a;
    margin-bottom: 5px;
  }

  .mainText {
    line-height: 26.16px;
    font-size: 20px;
    color: #0a0a0a;
    font-weight: 400;
  }
  .card {
    width: 100%;
    max-width: 560px;
    min-height: 300px;
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 1);
    border-radius: 22px;
    padding: 45px 50px;
    margin-top: 30px;
    margin-bottom: 60px;

    // SUMMARY
    .summary {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      .employee {
        display: flex;
        gap: 10px;
        align-items: center;
        padding-left: 10px;
        img {
          width: 80px;
          height: 80px;
          border-radius: 12px;
          object-fit: cover;
        }

        p {
          font-size: 16px;
          transform: translateY(-18px);
          color: #0a0a0a;
          text-align: center;
        }
      }

      .line {
        height: 63.5px;
        border: 1px solid #060a3542;
      }

      .service {
        display: flex;
        flex-direction: column;
        align-items: center;
        // padding: 0 15px;
        text-align: center;

        h5 {
          font-weight: 600;
          color: #0a0a0a;
          font-size: 20px;
          line-height: 26.16px;
        }

        p {
          font-size: 15px;
          line-height: 19.62px;
          color: #000000;
          max-width: 100px;
        }
      }

      .serviceOthers {
        display: flex;
        flex-direction: column;
        gap: 8px;
        // padding: 0 10px;

        .block {
          height: 27px;
          border-radius: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          p {
            font-weight: 600;
            font-size: 14px;
            transform: translateY(-1px);
          }

          svg {
            width: 14px;
            height: 14px;
          }

          &:nth-child(1) {
            min-width: 103px;
          }

          &:nth-child(2) {
            min-width: 76px;
          }
        }
      }
    }

    // CALENDAR
    .calendar {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      h4 {
        color: #0a0a0a;
        font-size: 24px;
        font-weight: 600;
        line-height: 31.39px;
        margin-bottom: 12px;
      }
    }

    // TIMESLOTS
    .slots {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      animation-name: fade2;
      animation-duration: 800ms;
      animation-iteration-count: 1;
      animation-direction: normal;

      @keyframes fade2 {
        0% {
          opacity: 0;
          transform: translateY(100px);
        }
        100% {
          opacity: 1;
          transform: translateY(0px);
        }
      }

      h4 {
        color: #0a0a0a;
        font-size: 24px;
        font-weight: 600;
        line-height: 31.39px;
        margin-bottom: 12px;
      }

      .customTime {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        padding: 20px;
        background-color: #dfdfdf48;
        border-radius: 5px;
        margin-bottom: 15px;

        button {
          width: 130px;
          height: 34px;
          border-radius: 22px;
          border: none;
          background: #de1468;
          font-size: 16px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0em;
          color: #ffffff;
          cursor: pointer;
        }
      }

      .timeslotsError {
        color: red;
        font-size: 14px;
      }

      .timeRow {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
  
        img {
          width: 15px;
          height: 15px;
        }
      }

      .items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;

        .slot {
          width: 100px;
          min-height: 33px;
          height: auto;
          border-radius: 32px;
          padding: 2px 0px;
          border: 1px solid #0a0a0a;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 300ms ease-in-out;

          p {
            font-weight: 600;
            color: #0a0a0a;
            font-size: 20px;
            text-align: center;
            margin-bottom: -1px;
            transition: all 300ms ease-in-out;
          }
        }
      }

      .waitingListSignup {
        p {
          font-size: 18px;
          line-height: 32px;
        }

        button {
          border: none;
          height: 40px;
          width: fit-content;
          padding: 0px 15px;
          border-radius: 10px;
          color: #ffffff;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }

    // DROPDOWNS
    .dropdowns {
      display: flex;
      flex-direction: column;
      gap: 8px;

      h4 {
        margin-bottom: 10px;
        font-weight: 300;
      }
      .row {
        display: flex;
        align-items: center;
        gap: 15px;
        select {
          width: 100%;
          height: 48px;
          border-radius: 12px;
          background: #f9f9f9;
          border: 1px solid #d3d3d3;
          padding: 0px 15px;
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          letter-spacing: 0em;
          color: #a7a7a7;
          /** for the dropdown indicator */
          appearance: none;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-position: left 0.8rem center;
          background-size: 1em;
        }
      }
      .error {
        color: red;
      }
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;

    button {
      width: 206px;
      height: 44px;
      border-radius: 22px;
      border: none;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      cursor: pointer;
      &:nth-child(1) {
        color: #1c1c1c;
        border: 1px solid #1c1c1c;
        background: transparent;
      }
      &:nth-child(2) {
        color: #ffffff;
        background: #de1468;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .container {
    .card {
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: 540px) {
  .container {
    .card {
      padding: 25px 30px;

      .summary {
        gap: 0px;
        .employee {
          gap: 8px;

          img {
            width: 60px;
            height: 60px;
          }

          p {
            font-size: 14px;
          }
        }

        .line {
          height: 50px;
        }

        .service {
          padding: 0 0;
          h5 {
            font-size: 18px;
            line-height: 100%;
          }

          p {
            font-size: 13px;
            line-height: 100%;
          }
        }

        .serviceOthers {
          gap: 6px;
          padding: 0px;

          .block {
            height: 22px;
            gap: 5px;

            p {
              font-size: 12px;
              margin-bottom: -2px;
            }

            svg {
              width: 11px;
              height: 11px;
            }

            &:nth-child(1) {
              min-width: 80px;
              max-width: 95px;
            }

            &:nth-child(2) {
              min-width: 70px;
              max-width: 85px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    margin-bottom: 50px;
    h1 {
      line-height: 38.86px;
      font-size: 26px;
    }
    .mainText {
      line-height: 24.16px;
      font-size: 18px;
    }
    .card {
      padding: 20px 15px;
      .slots {
        h4 {
          font-size: 20px;
          margin-bottom: 8px;
        }

        .items {
          gap: 10px;

          .slot {
            width: calc(33.333% - 10px);
            min-height: 33px;

            p {
              font-size: 18px;
            }
          }
        }
      }

      .dropdowns {
        .row {
          flex-direction: column;
        }
      }
    }
    .btns {
      width: 100%;
      gap: 10px;
      button {
        width: 100%;
        height: 40px;
        border-radius: 22px;
        border: none;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .container {
    .card {
      .summary {
        .employee {
          gap: 6px;

          img {
            width: 40px;
            height: 40px;
          }

          p {
            font-size: 12px;
            text-align: center;
            transform: translateY(-10px);
          }
        }

        .service {
          padding: 0 0;
          h5 {
            font-size: 16px;
            line-height: 100%;
            text-align: center;
          }

          p {
            font-size: 12px;
            line-height: 100%;
            text-align: center;
          }
        }

        .line {
          margin: 0px 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .container {
    h1 {
      line-height: 35.86px;
    }
    .mainText {
      line-height: 20.16px;
    }
  }
}
