.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-name: fade;
  animation-duration: 800ms;
  animation-iteration-count: 1;
  animation-direction: normal;

  @keyframes fade {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
    color: #0a0a0a;
    margin-bottom: 5px;
  }

  .mainText {
    line-height: 26.16px;
    font-size: 20px;
    color: #0a0a0a;
    font-weight: 400;
  }

  .clients,
  .employees {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 560px;
    gap: 18px;
    margin-bottom: 60px;
    margin-top: 30px;

    .card {
      box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
      background: rgba(255, 255, 255, 1);
      width: 126px;
      height: 116px;
      border-radius: 22px;
      border: 1px solid rgba(255, 255, 255, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      cursor: pointer;
      transition: all 300ms ease-in-out;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
      }
      p {
        color: rgba(10, 10, 10, 1);
        line-height: 20.93px;
        font-size: 16px;
        transition: all 300ms ease-in-out;
        text-align: center;
      }
    }
  }

  button {
    width: 206px;
    height: 44px;
    border-radius: 22px;
    border: none;
    background: #de1468;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: #ffffff;
    cursor: pointer;
  }
}

@media screen and (max-width: 480px) {
  .container {
    margin-bottom: 50px;
    h1 {
      line-height: 38.86px;
      font-size: 26px;
    }
    .mainText {
      line-height: 24.16px;
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 400px) {
  .container {
    h1 {
      line-height: 35.86px;
    }
    .mainText {
      line-height: 20.16px;
    }
  }
}
