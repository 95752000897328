.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 0px 20px 30px 20px;

  .wrapper {
    width: 100%;
    max-width: 1600px;

    .main {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      gap: 60px;

      .column {
        display: flex;
        flex-direction: column;
        .heading {
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0em;
          text-align: right;
          color: #000000;
          margin-bottom: 20px;
        }

        .cards {
          display: flex;
          align-items: baseline;
          flex-wrap: wrap;
          gap: 50px;
          width: fit-content;

          .card {
            width: 330px;
            height: auto;
            border-radius: 22px;
            padding: 30px;
            display: flex;
            flex-direction: column;
            background-color: #ffffff;
            border: 1px solid #ffffff;
            box-shadow: 0px 4px 34px 0px #0000001a;
            cursor: pointer;
            transition: all 250ms ease-in-out;

            svg {
              width: 50px;
              height: 50px;
              margin-bottom: 25px;
            }
            p {
              font-size: 24px;
              font-weight: 400;
              line-height: 32px;
              letter-spacing: 0em;
              color: #0a0a0a;
            }
            .title {
              margin-bottom: 5px;
            }
            .revealText {
              margin-bottom: 15px;
              font-size: 16px;
              cursor: pointer;
            }
            .exportBtn {
              margin-top: 10px;
              background-color: #c7cede;
              width: fit-content;
              border: none;
              border-radius: 7px;
              font-size: 16px;
              font-weight: 700;
              padding: 10px 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 7px;
              cursor: pointer;
              img {
                width: 15px;
                height: 15px;
              }
            }

            &:hover {
              border: 1px solid #de1468;
              svg {
                stroke: #de1468;
              }
              p {
                color: #de1468;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    margin-top: 30px;
    .wrapper {
      flex-direction: column;
      align-items: flex-start;

      .main {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .wrapper {
      .main {
        .column {
          width: 100%;
          .cards {
            gap: 35px;
            .card {
              width: 100%;
              max-width: 350px;
            }
          }
        }
      }
    }
  }
}
