.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0px;
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 380px;

    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: 0em;
      color: #0a0a0a;
      margin-bottom: 12px;
    }

    .mainText {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
      color: #0a0a0a;
      margin-bottom: 35px;
    }

    .form {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 22px;
      margin-bottom: 22px;
      .field {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;

        label {
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0em;
          color: #0a0a0a;

          span {
            color: #de1468;
          }
        }

        input {
          width: 100%;
          height: 48px;
          border-radius: 12px;
          background: #f9f9f9;
          border: 1px solid #d3d3d3;
          color: #a7a7a7;
          padding: 0px 20px;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0em;
          transition: all 250ms ease-in-out;

          &:focus,
          &:active {
            background: #ffffff;
            border: 1px solid #de1468;
            box-shadow: 0px 4px 34px 0px #0000001a;
          }
        }
      }
    }

    .discount {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 25px;

      h4 {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
        color: #0a0a0a;
        margin-bottom: 5px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: #0a0a0a;
        margin-bottom: 15px;
      }

      .code {
        width: 100%;
        height: 66px;
        border-radius: 12px;
        background: #f9f9f9;
        border: 1px solid #de1468;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        h3 {
          font-size: 32px;
          font-weight: 600;
          line-height: 42px;
          letter-spacing: 0em;
          text-align: center;
          color: #de1468;
        }
      }
    }

    button {
      width: 100%;
      height: 44px;
      border-radius: 22px;
      border: none;
      background: #de1468;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0em;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 20px 5px;
    .wrapper {
      h2 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px;
      }

      .mainText {
        font-size: 14px;
        max-width: 250px;
        width: 100%;
        line-height: 18px;
        margin-bottom: 25px;
      }

      .form {
        gap: 10px;
        margin-bottom: 15px;
        .field {
          gap: 6px;
          margin-bottom: 5px;
          label {
            font-size: 16px;
          }
          input {
            font-size: 14px;
          }
        }
      }

      .discount {
        margin-bottom: 25px;
        width: 100%;

        h4 {
          font-size: 18px;
          line-height: 24px;
        }

        p {
          width: 100%;
          max-width: 220px;
          font-size: 14px;
          line-height: 18px;
        }

        .code {
          height: 66px;

          h3 {
            font-size: 24px;
          }
        }
      }
    }
  }
}
