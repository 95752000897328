.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 0px 0px 30px 20px;

  .toggleView {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 50px;

    .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 10px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 5px;
      cursor: pointer;

      svg {
        width: 25px;
        height: 25px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .wrapper {
    width: 100%;
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .dropdown {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      select {
        width: 100%;
        max-width: 400px;
        height: 48px;
        border-radius: 12px;
        background: #f9f9f9;
        border: 1px solid #d3d3d3;
        padding: 0px 15px;
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        color: #a7a7a7;
        /** for the dropdown indicator */
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: left 0.8rem center;
        background-size: 1em;
      }
    }
    .sections {
      display: flex;
      flex-wrap: nowrap;
      gap: 30px;
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    margin-top: 30px;
    padding-left: 0px;
    .wrapper {
      flex-direction: column;
      align-items: flex-start;
      .sections {
        padding-right: 20px;
        padding-left: 20px;
        gap: 40px;
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
}
