.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .cover {
    width: 100%;
    height: 300px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    // background-position: 0px;
    position: relative;
    .camera {
      position: absolute;
      width: 40px;
      height: 40px;
      left: 15px;
      bottom: -18px;
      top: unset;
      background-color: var(--brand-text-color);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      input {
        position: absolute;
        width: 40px;
        height: 40px;
        left: 0px;
        top: 0px;
        opacity: 0;
        cursor: pointer;
      }
      img {
        width: 20px;
      }
    }
  }
  .profile {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    align-self: center;
    transform: translateY(-95px);
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
    .camera {
      position: absolute;
      width: 40px;
      height: 40px;
      left: 12px;
      bottom: 12px;
      background-color: var(--brand-text-color);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      input {
        cursor: pointer;
        position: absolute;
        width: 40px;
        height: 40px;
        left: 0px;
        top: 0px;
        opacity: 0;
      }
      img {
        width: 20px;
      }
    }
  }

  .heading {
    transform: translateY(-70px);
    align-self: center;
    display: flex;
    align-items: center;
    input {
      border: 1px solid rgb(209, 213, 219/1);
      padding: 5px;
      font-size: 18px;
    }
    h2 {
      text-align: center;
      color: var(--brand-text-color);
    }
    img {
      margin-left: 12px;
    }
  }

  .btnsContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    transform: translateY(-30px);

    button {
      background-color: rgb(17, 24, 39/1);
      border-radius: 5px;
      border: none;
      color: rgb(255, 255, 255/1);
      width: 100%;
      max-width: 250px;
      height: 45px;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      img {
        width: 18px;
        margin-bottom: 2px;
      }
    }
  }

  .switchContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin: 10px 0px;
  }
}
