.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  height: 137px;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  padding: 20px 40px 20px 20px;
  margin-bottom: 30px;

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    color: #0a0a0a;
  }

  .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 650px;

    .block {
      display: flex;
      flex-direction: column;
      align-items: center;

      h4 {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
        color: #0a0a0a;
      }
      h3 {
        font-size: 20px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        color: #d00254;
      }
    }
    .line {
      height: 61px;
      border: 1px solid #d2d2d2;
    }
  }

  .btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    button {
      width: 172px;
      height: 44px;
      border-radius: 22px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      cursor: pointer;
      p {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        color: #ffffff;
      }

      &:nth-child(1) {
        background-color: #270c66;
      }
      &:nth-child(2) {
        background-color: #e30000;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .container {
    padding: 20px 30px 20px 20px;
    h2 {
      font-size: 21px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .container {
    flex-wrap: wrap;
    height: auto;
    gap: 15px;
    h2 {
      flex-grow: 2;
      width: 100%;
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    h2 {
      font-size: 20px;
    }
    .items {
      max-width: 520px;
      .block {
        h4 {
          font-size: 18px;
          margin-bottom: 7px;
        }
        h3 {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
    h2 {
      font-size: 18px;
      line-height: 24px;
    }
    .items {
      max-width: 400px;
      .block {
        h4 {
          font-size: 14px;
          margin-bottom: 2px;
        }
        h3 {
          font-size: 14px;
        }
      }
      .line {
        height: 50px;
      }
    }

    .btns {
      gap: 10px;
      flex-direction: row;
      button {
        width: 126px;
        height: 34px;
        gap: 8px;
        p {
          font-size: 14px;
          line-height: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 20px 15px 20px 15px;
    align-items: center;
    h2 {
      font-size: 16px;
      line-height: 16px;
      text-align: center;
    }
    .items {
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      .block {
        width: 45%;
        h4 {
          font-size: 12px;
          margin-bottom: 0px;
          line-height: 18px;
        }
        h3 {
          font-size: 12px;
        }
      }
      .line {
        display: none;
      }
    }
  }
}
