.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  animation-name: fade;
  animation-duration: 800ms;
  animation-iteration-count: 1;
  animation-direction: normal;
  width: 100%;

  @keyframes fade {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
    color: #0a0a0a;
    margin-bottom: 5px;
  }

  .mainText {
    line-height: 26.16px;
    font-size: 20px;
    color: #0a0a0a;
    font-weight: 400;
  }

  .services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: 100%;
    max-width: 560px;
    margin-top: 30px;
    margin-bottom: 60px;

    .card {
      width: 100%;
      height: auto;
      min-height: 116px;
      border-radius: 22px;
      padding: 20px 30px;
      display: flex;
      align-items: center;
      box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(255, 255, 255, 1);
      cursor: pointer;
      transition: all 300ms ease-in-out;
      justify-content: space-between;
      .img {
        width: 80px;
        height: 80px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;

        h3 {
          font-size: 40px;
          font-weight: 600;
          color: rgba(255, 255, 255, 1);
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-width: 60%;
        .name {
          font-weight: 600;
          font-size: 20px;
          line-height: 26.16px;
          color: #0a0a0a;
        }

        .desc {
          font-size: 15px;
          line-height: 19.62px;
          margin-bottom: 8px;
          color: #000000;
        }

        .row {
          display: flex;
          align-items: center;
          gap: 16px;

          .block {
            height: 27px;
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            p {
              font-weight: 600;
              font-size: 14px;
            }

            svg {
              width: 14px;
              height: 14px;
            }

            &:nth-child(1) {
              min-width: 103px;
            }

            &:nth-child(2) {
              min-width: 76px;
            }
          }
        }
      }

      button {
        height: 77px;
        width: 71px;
        border-radius: 22px;
        border: none;
        background: rgba(20, 6, 53, 1);
        padding: 23px, 15px, 24px, 15px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;

    button {
      width: 206px;
      height: 44px;
      border-radius: 22px;
      border: none;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      cursor: pointer;
      &:nth-child(1) {
        color: #1c1c1c;
        border: 1px solid #1c1c1c;
        background: transparent;
      }
      &:nth-child(2) {
        color: #ffffff;
        background: #de1468;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .container {
    .services {
      gap: 20px;
      max-width: 450px;
      margin-bottom: 40px;

      .card {
        height: auto;
        padding: 20px 15px;
        min-height: 95px;

        .img {
          width: 65px;
          height: 65px;
          border-radius: 12px;
          margin-left: 15px;

          h3 {
            font-size: 30px;
          }
        }

        .content {
          .name {
            font-size: 16px;
            line-height: 22px;
          }

          .desc {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 5px;
            max-width: 90%;
          }

          .row {
            gap: 10px;

            .block {
              height: 22px;
              gap: 3px;

              p {
                font-size: 12px;
                transform: translateY(-1px);
              }

              svg {
                width: 11px;
                height: 11px;
                transform: translateY(-0.5px);
              }

              &:nth-child(1) {
                min-width: 70px;
              }

              &:nth-child(2) {
                min-width: 80px;
              }
            }
          }
        }

        button {
          height: 50px;
          width: 50px;
          border-radius: 10px;
          padding: 0;
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    margin-bottom: 50px;
    h1 {
      line-height: 38.86px;
      font-size: 26px;
    }
    .mainText {
      line-height: 24.16px;
      font-size: 18px;
    }
    .services {
      .card {
        .content {
          .row {
            .block {
              min-width: auto !important;
              padding: 0px 7px;
            }
          }
        }
      }
    }
    .btns {
      width: 100%;
      gap: 10px;
      button {
        width: 100%;
        height: 40px;
        border-radius: 22px;
        border: none;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .container {
    h1 {
      line-height: 35.86px;
    }
    .mainText {
      line-height: 20.16px;
    }
    .services {
      .card {
        .content {
          .row {
            .block {
              height: 18px;
              p {
                font-size: 10px;
              }

              svg {
                width: 10px;
                height: 10px;
              }
            }
          }
        }
      }
    }
  }
}
