.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 0px 0px 30px 0px;

  .wrapper {
    width: 100%;
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    .sections {
      width: 100%;
      transform: translateX(60px);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      padding-left: 20px;
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    margin-top: 30px;
    .wrapper {
      flex-direction: column;
      align-items: flex-start;
      
      .sections {
        transform: translateX(0px);
        padding: 0px 20px;
      }
    }
  }
}
