.react-switch-handle {
  width: 19px !important;
  height: 19px !important;
  margin-top: 3px;
  margin-left: 3px;
}

// === CALENDAR 1 ===

// active
.react-calendar {
  width: 100% !important;
  // max-width: 517px !important;
  font-family: "Noto Sans Hebrew", sans-serif !important;
  background: #270c660d !important;
  padding: 30px 30px 40px 30px !important;
  border-radius: 22px !important;
  border: none !important;
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  abbr {
    font-size: 20px !important;
    font-weight: 400 !important;
    text-decoration: none;
    font-family: "Noto Sans Hebrew", sans-serif !important;
    color: #656565;
  }
}

.react-calendar__tile.react-calendar__month-view__days__day {
  height: 50px;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  font-family: "Noto Sans Hebrew", sans-serif !important;

  abbr {
    font-size: 20px !important;
    letter-spacing: 0px;
    color: #000;
    font-family: "Noto Sans Hebrew", sans-serif !important;
  }

  &:disabled {
    background-color: transparent;

    abbr {
      color: #bdbdbd;
      font-family: "Noto Sans Hebrew", sans-serif !important;
    }
  }
}

.react-calendar__month-view__days__day--weekend {
  color: #5e6167;
  font-family: "Noto Sans Hebrew", sans-serif !important;
}

// active
.react-calendar__tile--active {
  background: #de1468 0% 0% no-repeat padding-box !important;
  border: none !important;
  font-family: "Noto Sans Hebrew", sans-serif !important;

  abbr {
    color: #ffffff !important;
    font-family: "Noto Sans Hebrew", sans-serif !important;
  }
}

// current day
.react-calendar__tile--now {
  background: transparent 0% 0% no-repeat padding-box;
  font-family: "Noto Sans Hebrew", sans-serif !important;

  abbr {
    color: #000;
    font-family: "Noto Sans Hebrew", sans-serif !important;
  }
}

// current day but disabled
.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  background-color: transparent !important;

  abbr {
    color: #bdbdbd !important;
    font-family: "Noto Sans Hebrew", sans-serif !important;
  }
}

.react-calendar__navigation {
  padding: 0px 20px 0px 4px !important;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.react-calendar__navigation__label {
  display: flex;
  align-items: center;
  order: 1;
  border: none;
  span {
    color: #0a0a0a !important;
    font-size: 24px !important;
  }
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 0px;
  color: #292929;
  font-weight: bold;
  padding-left: 14px;
  font-family: "Noto Sans Hebrew", sans-serif !important;
}

// prev
.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  order: 2;
  transform: rotate(180deg);
  margin-left: -40px;
  font-size: 20px;
  color: #0a0a0a;
  border: none;
  background-color: transparent !important;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  order: 3;
  background-color: transparent !important;
  transform: rotate(180deg);
  margin-right: 19px;
  font-size: 20px;
  color: #0a0a0a;
  border: none;
}

@media screen and (max-width: 768px) {
  .react-calendar {
    max-width: 100% !important;
    padding: 20px 10px 20px 10px !important;
  }

  .react-calendar__month-view__weekdays__weekday {
    abbr {
      font-size: 14px;
    }
  }

  .react-calendar__tile.react-calendar__month-view__days__day {
    abbr {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 480px) {
  .react-calendar__navigation {
    margin-bottom: 8px;
  }
  .react-calendar__navigation__label {
    span {
      font-size: 18px !important;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    abbr {
      font-size: 16px !important;
    }
  }

  .react-calendar__tile.react-calendar__month-view__days__day {
    height: 40px;
    abbr {
      font-size: 16px !important;
    }
  }
}
