.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: auto;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  padding: 30px 40px 40px 20px;
  margin-bottom: 30px;

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    color: #0a0a0a;
    margin-bottom: 20px;
  }

  .cards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    .card {
      width: 150px;
      height: 155px;
      border-radius: 22px;
      background: #f4f4f4;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 15px;

      h4 {
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
        color: #de1468;
      }
      hr {
        width: 60px;
        border: 1px solid #270c66;
      }
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        color: #270c66;
        width: 100%;
        max-width: 112px;
      }
    }

    .bigCard {
      width: 261px;
      height: auto;
      min-height: 155px;
      border-radius: 22px;
      background: #06a816;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
      h4 {
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
        color: #ffffff;
      }
      hr {
        width: 60px;
        border: 1px solid #ffffff;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #ffffff;
        width: 100%;
        max-width: 170px;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .container {
    padding: 30px 30px 40px 20px;
    h2 {
      font-size: 21px;
      margin-bottom: 10px;
    }
    .cards {
      .card {
        width: auto;
        flex-grow: 1;
        height: 137px;
        gap: 10px;

        h4 {
          font-size: 28px;
          line-height: 36px;
        }
        hr {
          width: 60px;
        }
        p {
          font-size: 14px;
          line-height: 20px;
          max-width: 100px;
        }
      }
      .bigCard {
        width: auto;
        padding: 0px 10px;
        height: 145px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .container {
    h2 {
      font-size: 20px;
    }
    .cards {
      flex-wrap: wrap;
      justify-content: flex-start;
      .card {
        min-width: 150px;
        flex-grow: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 5px;
    }
    .cards {
      justify-content: center;
      .card {
        width: 30%;
        min-width: auto;
        flex-grow: 0;
        height: 115px;
        h4 {
          font-size: 22px;
          line-height: 30px;
        }
        hr {
          width: 55px;
        }
        p {
          font-size: 12px;
          line-height: 14px;
          max-width: 80px;
        }
      }
      .bigCard {
        padding: 0px 10px;
        width: 30%;
        min-width: auto;
        flex-grow: 0;
        height: 115px;
        h4 {
          font-size: 22px;
          line-height: 30px;
        }
        hr {
          width: 55px;
        }
        p {
          font-size: 12px;
          line-height: 14px;
          max-width: 120px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 20px 15px 20px 15px;
    align-items: center;
    h2 {
      text-align: center;
      font-size: 16px;
      line-height: 16px;
    }
    .cards {
      .card {
        width: 47%;
      }
      .bigCard {
        width: 100%;
        max-width: 200px;
      }
    }
  }
}
