.container {
  width: 25%;
  min-width: 354px;
  height: 287px;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  padding: 20px 30px;

  h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: #0a0a0a;
  }
  .mainText {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color: #0a0a0a;
    margin-bottom: 15px;
  }

  button {
    width: 172px;
    height: 44px;
    border-radius: 22px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    cursor: pointer;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;

    .card {
      display: flex;
      align-items: center;
      gap: 5px;
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: center;
        color: #140635;
      }
    }

    button {
      background-color: transparent;
      border: 1px solid #270c66;
      color: #270c66;
    }
  }

  .others {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
      color: #0a0a0a;
      margin-bottom: 5px;
    }
    button {
      margin-top: 15px;
      background: #270c66;
      border: none;
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 100%;
    min-width: auto;
    padding: 20px;
    button {
      width: auto;
      height: 38px;
      padding: 0px 10px;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0em;
    }
  }
}
