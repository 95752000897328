.container {
  width: 100%;
  max-width: 530px;
  min-width: 530px;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  cursor: pointer;
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    height: 118px;
    padding: 20px 20px;
    position: relative;

    .hours {
      margin-left: 18px;
      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        color: #000000;
      }
      .date {
        font-size: 14px;
        font-weight: 600;
        line-height: 15px;
        letter-spacing: 0em;
        color: #d00254;
        margin-top: 4px;
      }
    }

    .line {
      border: 1px solid #d2d2d2;
      height: 61px;
      margin-left: 12px;
    }

    .service {
      margin-left: 35px;
      flex-grow: 2;
      h4 {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
      }
      .adminComment {
        margin-top: 10px;
        font-weight: 600;
        font-size: 14px;
      }
      .row {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 12px;

        .block {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 3px;
          width: auto;
          img {
            width: 13px;
            height: 13px;
          }

          p {
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            letter-spacing: 0em;
            color: #d00254;
            margin-bottom: 2px;
          }
        }
      }
    }

    .classBtn {
      width: 105px;
      height: 44px;
      border-radius: 22px;
      border: 1px solid #270c66;
      background-color: transparent;
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0em;
      color: #270c66;
      margin-left: 40px;
      cursor: pointer;
    }

    .status {
      width: 123px;
      height: 27px;
      border-radius: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 40px;

      p {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .completed {
      background: rgba(182, 255, 189, 0.36);
      border: 1px solid #06a816;

      p {
        color: #06a816;
      }
    }

    .pending {
      background: rgba(255, 218, 121, 0.36);
      border: 1px solid #fab400;

      p {
        color: #e2a710;
      }
    }

    .arrow {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .body {
    transition: all 250ms ease-in-out;
    padding: 0px 20px;

    .line1 {
      border: 1px solid #d2d2d2;
    }

    ul {
      li {
        list-style: none;
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 14px 0px;
        border-bottom: 1px solid #f1f1f1;
        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }

        p {
          font-size: 20px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0em;
          color: #000000;
        }
      }
    }
  }
  .open {
    height: 342px;
  }
  .hide {
    height: 0px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    max-width: 530px;
    min-width: 470px;
    .header {
      height: 90px;
      .hours {
        margin-left: 14px;
        p {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .line {
        height: 52px;
        margin-left: 12px;
      }

      .service {
        margin-left: 35px;
        h4 {
          font-size: 16px;
          line-height: 24px;
        }
        .adminComment {
          margin-top: 8px;
        }
        .row {
          gap: 10px;
          margin-top: 8px;

          .block {
            min-width: 84px;
            height: 24px;
            p {
              line-height: 12px;
            }
          }
        }
      }

      .status {
        width: 110px;
        height: 24px;

        p {
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
    .body {
      padding: 0px 20px;

      ul {
        li {
          padding: 14px 0px;
          p {
            font-size: 16px;
            line-height: 24px;
          }
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .open {
      height: 330px;
    }
  }
}

@media screen and (max-width: 540px) {
  .container {
    min-width: 100%;
    max-width: 100%;
    .header {
      height: auto;
      flex-wrap: wrap;
      .hours {
        margin-left: 8px;
        p {
          font-size: 14px;
          line-height: 22px;
        }
      }

      .line {
        height: 48px;
        margin-left: 10px;
      }

      .service {
        margin-left: 25px;
        h4 {
          font-size: 16px;
          line-height: 24px;
        }
        .adminComment {
          margin-top: 6px;
        }
        .row {
          margin-top: 6px;
        }
      }
      .classBtn {
        width: 90px;
        height: 32px;
        font-size: 14px;
        line-height: 19px;
        margin-left: 40px;
        margin-top: 10px;
      }
      .arrow {
        left: 15px;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .container {
    .header {
      .status {
        margin-left: 125px;
        margin-top: 15px;
      }
    }
    .body {
      ul {
        li {
          p {
            font-size: 15px;
            line-height: 23px;
          }
        }
      }
    }
    .open {
      height: 320px;
    }
  }
}
