.container {
  width: 100%;
  max-width: 657px;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 4px 34px 0px #0000001a;
  padding: 20px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .id {
    width: 209px;
    height: 26px;
    border-radius: 22px;
    background: #140635;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    margin-bottom: 15px;

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
    }
  }

  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: -95px;

    .icon {
      width: 25px;
      height: 25px;
      position: absolute;
      border-radius: 50%;
      box-shadow: 0px 2.5964913368225098px 22.07017707824707px 0px #0000001a;

      img {
        width: 25px;
        height: 25px;
        border: 0.65px solid #de1468;
        background-color: #ffffff;
        border-radius: 50%;
        padding: 4px;
      }

      input {
        position: absolute;
        width: 25px;
        height: 25px;
        opacity: 0;
        top: 0;
        left: 0;
      }
    }
    .cover {
      width: 100%;
      position: relative;
      height: 199px;

      .img {
        width: 100%;
        height: 199px;
        object-fit: cover;
        border-radius: 22px;
      }
      .icon {
        top: 12px;
        left: 12px;
      }
    }

    .main {
      transform: translateY(-115px);
      width: 173px;
      height: 170px;
      border-radius: 100px;
      background: #ffffff;
      border: 2px solid #ffffff;
      box-shadow: 0px 4px 34px 0px #0000001a;
      position: relative;

      .img {
        border-radius: 100px;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .icon {
        bottom: 0px;
        left: 27px;
      }
    }
  }

  .body {
    width: 100%;
    max-width: 494px;
    .name {
      font-size: 24px;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: 0em;
      color: #000000;
      text-align: center;
      margin-bottom: 30px;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 30px;
      button {
        width: 48.5%;
        height: 44px;
        border-radius: 22px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        cursor: pointer;
      }

      .daysBtn {
        color: #ffffff;
        background: #270c66;
      }
      .servicesBtn {
        color: #ffffff;
        background: #140635;
      }
    }

    .check {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 30px;

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        color: #000000;
      }
    }

    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 35px;
      h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0em;
        color: #0a0a0a;
        margin-bottom: 15px;
      }
      textarea {
        width: 100%;
        height: 100px;
        border-radius: 12px;
        background: #f9f9f9;
        border: 1px solid #de1468;
        resize: none;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0em;
        color: #afafaf;
        padding: 10px 15px;
        margin-bottom: 8px;
      }
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          color: #0a0a0a;
        }
      }
    }

    .form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 25px;
      .field {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
        width: 47%;

        label {
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0em;
          color: #0a0a0a;

          span {
            color: #de1468;
          }
        }

        input {
          width: 100%;
          height: 48px;
          border-radius: 12px;
          background: #f9f9f9;
          border: 1px solid #d3d3d3;
          color: #a7a7a7;
          padding: 0px 20px;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0em;
          transition: all 250ms ease-in-out;

          &:focus,
          &:active {
            background: #ffffff;
            border: 1px solid #de1468;
            box-shadow: 0px 4px 34px 0px #0000001a;
          }
        }

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          width: 100%;
          input {
            max-width: 100%;
          }
        }
      }
    }

    .color {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      .title {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 5px;

        h4 {
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: right;
          color: #0a0a0a;
        }
        span {
          width: 53px;
          height: 17px;
          padding: 2px 14px 3px 14px;
          border-radius: 22px;
          background: #06a816;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0em;
          color: #ffffff;
        }
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        color: #000000;
        margin-bottom: 20px;
      }

      .colors {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        .circle {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          opacity: 0.42;
          cursor: pointer;
        }
        .active {
          opacity: 1;
          border: 2px solid #de1468;
        }
      }
    }

    .gallery {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      .title {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 5px;

        h4 {
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: right;
          color: #0a0a0a;
        }
      }
      .text {
        font-size: 15px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        color: #000000;
        margin-bottom: 20px;
      }

      .upload {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 25px;

        .uploadBtn {
          width: 196px;
          height: 48px;
          border-radius: 22px;
          border: 1px solid #270c66;
          background: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          cursor: pointer;
          position: relative;
          
          input {
            position: absolute;
            top: 0;
            left: 0;
            width: 196px;
            height: 48px;
            border-radius: 22px;
            opacity: 0;
            z-index: 9999999;
          }
          img {
            width: 24px;
            height: 24px;
          }

          p {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0em;
            color: #270c66;
          }
        }

        .text {
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0em;
          color: #0a0a0a;
          margin-bottom: 0px;
        }
      }

      .images {
        width: 100%;
        padding: 20px 20px;
        background: #f9f9f9;
        border: 1px solid #de1468;
        border-radius: 12px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 18px;

        .image {
          width: 138px;
          height: 138px;
          border-radius: 4px;
          background: #ededed;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          .img {
            border-radius: 4px;
            width: 138px;
            height: 138px;
            object-fit: cover;
          }

          .minus {
            position: absolute;
            top: -8px;
            right: -8px;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: #de1414;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-weight: 800;
            letter-spacing: 0em;
            text-align: center;
            color: #ffffff;
            padding-bottom: 4px;
            cursor: pointer;
          }
        }
      }
    }

    .submit {
      width: 100%;
      height: 52px;
      border-radius: 22px;
      border: none;
      background: #de1468;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .body {
      .check {
        align-items: flex-start;
        p {
          transform: translateY(-3px);
          font-size: 14px;
          line-height: 18px;
        }
      }
      .form {
        width: 100%;
        .field {
          width: 100%;
          input {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 20px;

    .id {
      width: auto;
      height: 26px;
      padding: 0px 20px;
      p {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .profile {
      margin-bottom: -65px;
      .main {
        width: 130px;
        height: 130px;
        transform: translateY(-80px);
        .icon {
          left: 18px;
        }
      }
    }
    .body {
      .name {
        font-size: 20px;
        margin-bottom: 15px;
      }

      .btns {
        margin-bottom: 25px;
        button {
          height: 36px;
          font-size: 14px;
        }
      }
      .check {
        margin-bottom: 15px;
        p {
          font-size: 12px;
          line-height: 15px;
        }
      }
      .desc {
        margin-bottom: 15px;
        h4 {
          font-size: 18px;
          margin-bottom: 8px;
        }
        textarea {
          font-size: 14px;
          margin-bottom: 4px;
        }
        .row {
          p {
            font-size: 12px;
          }
        }
      }
      .form {
        gap: 10px;
        margin-bottom: 15px;
        .field {
          gap: 6px;
          margin-bottom: 5px;
          label {
            font-size: 16px;
          }
          input {
            font-size: 14px;
          }
        }
      }

      .gallery {
        .upload {
          flex-direction: column;
          align-items: flex-start;
          gap: 15px;
        }

        .images {
          gap: 10px;
          justify-content: space-between;
          .image {
            width: 48%;
          }
        }
      }

      .submit {
        height: 38px;
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .body {
      .gallery {
        .images {
          .image {
            height: 120px;
            width: 47%;
            img {
              width: 70px;
              height: 70px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .container {
    .body {
      .gallery {
        .images {
          .image {
            height: 100px;
            width: 47%;
            img {
              width: 60px;
              height: 60px;
            }
          }
        }
      }
    }
  }
}
