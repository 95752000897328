.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 0px 0px 30px 0px;

  .wrapper {
    width: 100%;
    max-width: 1640px;
    display: flex;
    z-index: 99;

    .main {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-left: 20px;
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    margin-top: 30px;
    .wrapper {
      flex-direction: column;
      align-items: flex-start;

      .main {
        padding: 0px 20px;
      }
    }
  }
}
